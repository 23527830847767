import { FilterCondition, User, UserRole, UserStat } from "src/graphql";
import { FilterType, SomeUserValues } from "src/types";

export const userSearch = (
  users: User[],
  filterText: string,
  excludeInactiveUsers: boolean,
  excludeAdmins?: boolean
) => {
  let filteredItems = users?.filter(
    (user: User) =>
      user.name.toLowerCase().includes(filterText.toLowerCase()) ||
      user.email.toLowerCase().includes(filterText.toLowerCase())
  );

  filteredItems = excludeInactiveUsers
    ? filteredItems.filter((user) => user.isActive)
    : filteredItems;

  filteredItems = excludeAdmins
    ? filteredItems?.filter(
        (item: SomeUserValues) => item.role !== UserRole.SuperAdmin
      )
    : filteredItems;

  return filteredItems;
};

export type UserPermsType = {
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isLead: boolean;
  isChw: boolean;
  isRP: boolean;
  atLeastOrgAdmin: boolean;
  atLeastOrgLead: boolean;
};
export const getUserPerms = (user?: User) => {
  if (!user) {
    return {
      isSuperAdmin: false,
      isAdmin: false,
      isLead: false,
      isChw: false,
      isRP: false,
      atLeastOrgAdmin: false,
      atLeastOrgLead: false,
      isAnalyticsViewer: false,
    };
  }
  const isSuperAdmin = user.role === UserRole.SuperAdmin;
  const isAdmin = user.role === UserRole.Admin;
  const isLead = user.role === UserRole.Lead;
  const isChw = user.role === UserRole.Chw;
  const isRP = user.role === UserRole.RecommendingProvider;
  const isAnalyticsViewer = user.role === UserRole.AnalyticsViewer;
  const atLeastOrgAdmin = isSuperAdmin || isAdmin;
  const atLeastOrgLead = atLeastOrgAdmin || isLead;
  return {
    isSuperAdmin,
    isAdmin,
    isLead,
    isChw,
    isRP,
    atLeastOrgAdmin,
    atLeastOrgLead,
    isAnalyticsViewer,
  };
};

export const userHasPerm = (user: User, perm: keyof UserPermsType) => {
  const userPerms = getUserPerms(user);
  return userPerms[perm];
};

export const userStatSearch = (
  userStats: UserStat[],
  filterText: string,
  filters: FilterType[]
) => {
  const filteredItems = userStats?.filter((user: UserStat) => {
    // filter by name
    if (
      !(
        user.name.toLowerCase().includes(filterText.toLowerCase()) ||
        user.email.toLowerCase().includes(filterText.toLowerCase())
      )
    ) {
      return false;
    }

    // filter by additional filters
    for (const filter of filters) {
      const rowValue =
        filter.filter === "activeMembers"
          ? user.assignedMembers?.length || 0
          : Number(user[filter.filter as keyof UserStat]);
      const filterValue = parseInt(filter.filterValue);
      const additionalFilterResult =
        (filter.condition === FilterCondition.Equals &&
          rowValue === filterValue) ||
        (filter.condition === FilterCondition.Gt && rowValue > filterValue) ||
        (filter.condition === FilterCondition.Gte && rowValue >= filterValue) ||
        (filter.condition === FilterCondition.Lt && rowValue < filterValue) ||
        (filter.condition === FilterCondition.Lte && rowValue <= filterValue);
      if (!additionalFilterResult) return false;
    }

    return true;
  });

  return filteredItems;
};
