export const NoteFields = `
  _id
  content
  redacted
  organizationId

  authorId
  authorName

  memberId
  externalResourceId
  externalResourceContactId
  activityId

  createdAt
  updatedAt
`;
