import { Reference, useMutation, useQuery } from "@apollo/client";
import { apolloCache } from "../client";
import { GET_GOALS } from "../Goal/queries";
import {
  CompleteActionResponse,
  DefaultResponse,
  GetFlowResponse,
  GetFlowsResponse,
  GetNextFlowQuestionResponse,
  MutationCreateFlowArgs,
  MutationDeleteFlowArgs,
  MutationUndoLastFlowProgressArgs,
  MutationUpdateFlowProgressArgs,
  QueryGetFlowArgs,
  QueryGetFlowByActivityArgs,
  QueryGetFlowsByMemberIdArgs,
  QueryGetNextFlowQuestionArgs,
  QueryOnboardingFlowArgs,
} from "../schemaTypes";
import {
  CREATE_FLOW,
  DELETE_FLOW,
  UNDO_LAST_FLOW_PROGRESS,
  UPDATE_FLOW_PROGRESS,
} from "./mutations";
import {
  GET_FLOW,
  GET_FLOWS_BY_MEMBER_ID,
  GET_FLOW_BY_ACTIVITY,
  GET_NEXT_FLOW_QUESTION,
  GET_ONBOARDING_FLOW,
} from "./queries";
import { GET_REFERRALS_BY_MEMBER } from "../Referral/queries";
import { GET_CAREPATHWAY_TEMPLATES_BY_MEMBER_ID } from "../CarePath/queries";

export const useMutationCreateFlow = (memberId: string) =>
  useMutation<{ createFlow: DefaultResponse }, MutationCreateFlowArgs>(
    CREATE_FLOW,
    {
      refetchQueries: [
        {
          query: GET_FLOWS_BY_MEMBER_ID,
          variables: { memberId },
        },
      ],
    }
  );

export const useQueryGetFlowsByMemberId = (memberId: string) =>
  useQuery<
    { getFlowsByMemberId: GetFlowsResponse },
    QueryGetFlowsByMemberIdArgs
  >(GET_FLOWS_BY_MEMBER_ID, { variables: { memberId } });

export const useQueryOnboardingFlow = (
  memberId: string,
  organizationId: string,
  activityId: string,
  onboardingToken: string
) =>
  useQuery<{ onboardingFlow: GetFlowResponse }, QueryOnboardingFlowArgs>(
    GET_ONBOARDING_FLOW,
    {
      variables: { memberId, organizationId, activityId },
      context: {
        headers: {
          "Pear-Onboarding-Survey": onboardingToken,
        },
      },
    }
  );

type QueryGetFlowByActivityOptions = {
  memberId: string;
  flowTemplateFamilyId: string;
  activityId: string;
  onboardingToken?: string;
};

export const useQueryGetFlowByActivity = ({
  memberId,
  flowTemplateFamilyId,
  activityId,
  onboardingToken,
}: QueryGetFlowByActivityOptions) =>
  useQuery<{ getFlowByActivity: GetFlowResponse }, QueryGetFlowByActivityArgs>(
    GET_FLOW_BY_ACTIVITY,
    {
      skip: !memberId || !activityId || !flowTemplateFamilyId,
      variables: { input: { memberId, flowTemplateFamilyId, activityId } },
      context: {
        headers: {
          ...(onboardingToken
            ? { "Pear-Onboarding-Survey": onboardingToken }
            : {}),
        },
      },

      // if a new flow has been created, add it to the member's flow query list
      onCompleted: ({ getFlowByActivity: { data: queriedFlow } }) => {
        if (!queriedFlow) return;

        apolloCache.modify({
          fields: {
            getFlowsByMemberId: (cached: { data: Reference[] }, helpers) => {
              const queriedFlowRef = helpers.toReference(queriedFlow);
              const existingEntry = cached.data.find(
                (ref) => ref.__ref === queriedFlowRef?.__ref
              );

              return existingEntry
                ? cached
                : { ...cached, data: [...cached.data, queriedFlowRef] };
            },
          },
        });
      },
    }
  );

export const useQueryGetFlow = (flowId: string) =>
  useQuery<{ getFlow: GetFlowResponse }, QueryGetFlowArgs>(GET_FLOW, {
    variables: { flowId },
  });

export const useMutationDeleteFlow = (flowId: string) =>
  useMutation<{ deleteQuestion: DefaultResponse }, MutationDeleteFlowArgs>(
    DELETE_FLOW,
    {
      variables: {
        flowId,
      },
    }
  );

export const useQueryGetNextFlowQuestion = (
  flowId: string,
  onboardingToken?: string
) =>
  useQuery<
    { getNextFlowQuestion: GetNextFlowQuestionResponse },
    QueryGetNextFlowQuestionArgs
  >(GET_NEXT_FLOW_QUESTION, {
    skip: !flowId,
    fetchPolicy: "network-only",
    variables: { flowId },
    ...(onboardingToken
      ? {
          context: {
            headers: {
              "Pear-Onboarding-Survey": onboardingToken,
            },
          },
        }
      : {}),
  });

export const useMutationUpdateFlowProgress = (
  memberId: string,
  onboardingToken?: string
) =>
  useMutation<
    { updateFlowProgress: CompleteActionResponse },
    MutationUpdateFlowProgressArgs
  >(UPDATE_FLOW_PROGRESS, {
    ...(onboardingToken
      ? {
          context: {
            headers: {
              "Pear-Onboarding-Survey": onboardingToken,
            },
          },
        }
      : {
          refetchQueries: [
            { query: GET_GOALS, variables: { memberId } },
            {
              query: GET_CAREPATHWAY_TEMPLATES_BY_MEMBER_ID,
              variables: { memberId },
            },
            GET_REFERRALS_BY_MEMBER,
            GET_FLOW,
          ],
        }),
  });

export const useMutationUndoLastFlowProgress = (onboardingToken?: string) =>
  useMutation<
    { undoLastFlowProgress: DefaultResponse },
    MutationUndoLastFlowProgressArgs
  >(UNDO_LAST_FLOW_PROGRESS, {
    ...(onboardingToken
      ? {
          context: {
            headers: {
              "Pear-Onboarding-Survey": onboardingToken,
            },
          },
        }
      : {}),
  });
