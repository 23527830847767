import {
  Button,
  Group,
  LoadingOverlay,
  MultiSelect,
  Stack,
  Text,
} from "@mantine/core";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import log from "loglevel";
import { useMemo, useState } from "react";
import toast from "src/libs/toast";
import {
  Activity,
  ActivityStatus,
  Integration,
  UpdateActivity,
  useMutationUpdateActivity,
  useQueryActivityTemplate,
  useQueryMember,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import { AppointmentFields } from "src/pages/templates-activity/TemplateForm";
import {
  AppointmentDetailsSchema,
  unwrapAppointmentDetails,
} from "src/pages/templates-activity/utils";
import * as Yup from "yup";
import { StyledLabel } from "../input";
import { FormikCheckbox } from "../input/FormikCheckbox";
import { FormikDateTimePickerInput } from "../input/FormikDateTimePickerInput";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from "../modal";
import { MemberNotes } from "../profile-details/MemberNotes";
import {
  BillingDetailsFields,
  BillingDetailsFormValues,
  BillingDetailsSchema,
  unwrapBillingDetails,
  wrapBillingDetails,
} from "./billing";
import { AlertTriangle } from "react-feather";

type EndActivityModalProps = {
  title: string;
  description?: string;
  message?: string;
  activity: Activity | null;
  opened: boolean;
  onClose: () => void;
};

export const PostCompletionUpdateActivityModal = ({
  title,
  description,
  message,
  activity,
  opened,
  onClose,
}: EndActivityModalProps) => {
  const { selectedOrganization } = useAuthContext();
  const [updateActivity, { loading: isLoading }] = useMutationUpdateActivity();
  const [selectedInsurance, setSelectedInsurance] = useState<string>();

  const { data: activityTemplateResponse, loading: activityTemplateLoading } =
    useQueryActivityTemplate(
      activity?.activityTemplateId ?? "",
      undefined,
      !Boolean(activity?.activityTemplateId)
    );

  const memberOptions = useMemo(
    () =>
      activity?.members.map((m) => ({
        label: m.memberDisplayName,
        value: m.memberId,
      })) ?? [],
    [activity]
  );

  const canBeBillable = useMemo(() => {
    return selectedOrganization.billingConfiguration?.enabled ?? false;
  }, [selectedOrganization.billingConfiguration?.enabled]);

  const memberId = activity?.members[0]?.memberId;
  const memberQuery = useQueryMember(
    memberId ?? "",
    [selectedOrganization._id],
    memberId === undefined
  );
  const member = useMemo(
    () => memberQuery.data?.member.data,
    [memberQuery.data?.member.data]
  );

  const initialValues = useMemo(() => {
    if (!activity || !member) return;

    return wrapFormValues({
      activity,
      canBeBillable,
      setSelectedInsurance,
      memberDiagnoses: member?.diagnoses,
      selectedMemberInsurance: selectedInsurance,
      memberInsurance: member?.primaryInsuranceCompany,
    });
  }, [activity, canBeBillable, selectedInsurance, member]);

  if (!activity || !initialValues) return null;

  const handleSubmit = async (values: EndActivityFormValues) => {
    try {
      const unwrappedFormValues = unwrapFormValues(values);

      const response = await updateActivity({
        variables: {
          input: {
            activityId: activity._id,
            updateActivity: {
              ...unwrappedFormValues,
              status: ActivityStatus.Complete,
            },
          },
        },
      });

      if (response.data?.updateActivity.success) {
        toast.success(message ?? "Updated activity");
        return onClose();
      }

      throw new Error(response.data?.updateActivity.message);
    } catch (error) {
      log.error(error);

      if (error instanceof Error) toast.error(error.message);
      else toast.error("Could not complete activity, please try again later");
    }
  };

  const showAppointmentInputs =
    selectedOrganization.activatedIntegrations?.includes(
      Integration.RedoxAppointmentScheduling
    );

  const isGroupEvent = activity.tasks?.[0]?.type === "Event";
  const preFillBillingFields = activity.status === ActivityStatus.InProgress;

  return (
    <Modal opened={opened} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={EndActivitySchema(isGroupEvent)}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          submitForm,
          values,
          isValid,
          isValidating,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <ModalHeader withSubHeader>{title}</ModalHeader>
              {description && <ModalSubHeader>{description}</ModalSubHeader>}

              <ModalBody spacing="md">
                <FormikDateTimePickerInput
                  name="startedAt"
                  label="Started at"
                  clearable={false}
                  required
                />

                <FormikDateTimePickerInput
                  name="completedAt"
                  label="Completed at"
                  clearable={false}
                  required
                />

                {!isValidating &&
                  values.startedAt &&
                  values.completedAt &&
                  //Calculates if activity duration is > 2 hours
                  new Date(values.completedAt).getTime() -
                    new Date(values.startedAt).getTime() >
                    2 * 60 * 60 * 1000 && (
                    <Group>
                      <AlertTriangle
                        color="var(--color-creamsicle)"
                        size={16}
                      />
                      <Text
                        size={13}
                        color="var(--color-creamsicle)"
                        align="left"
                      >
                        Activity is longer than 2 hours, is this correct?
                      </Text>
                    </Group>
                  )}

                {/* Non form value, just to display which members are part of the activity */}
                <MultiSelect
                  required
                  name="members"
                  label="Members"
                  placeholder="Activity members"
                  disabled
                  data={memberOptions}
                  value={activity?.members.map((m) => m.memberId) ?? []}
                />

                {activity.members.length > 0 && (
                  <Stack spacing={0}>
                    <StyledLabel>Notes</StyledLabel>
                    <MemberNotes
                      hideTitle
                      activityId={activity._id}
                      memberId={activity.members[0].memberId}
                    />
                  </Stack>
                )}
              </ModalBody>

              {canBeBillable && (
                <>
                  <ModalSubHeader mt="lg">Billing Details</ModalSubHeader>
                  <ModalBody spacing="md">
                    <FormikCheckbox
                      mt="sm"
                      name="billable"
                      label="Mark as billable"
                    />
                    {values.billable && (
                      <BillingDetailsFields
                        fieldsPrefix="billingDetails"
                        values={values.billingDetails}
                        setFieldValue={setFieldValue}
                        selectedInsurance={selectedInsurance}
                        setSelectedInsurance={setSelectedInsurance}
                        isForGroupEvent={isGroupEvent}
                        preFillBillingFields={preFillBillingFields}
                        activityTemplate={
                          activityTemplateResponse?.activityTemplate.data
                        }
                      />
                    )}
                  </ModalBody>
                </>
              )}

              {showAppointmentInputs && (
                <>
                  <ModalSubHeader mt="lg">Appointment Details</ModalSubHeader>
                  <ModalBody spacing="md">
                    <AppointmentFields
                      showAllFields={values.syncAppointment ?? false}
                    />
                  </ModalBody>
                </>
              )}

              <ModalFooter>
                <Button variant="outline" color="red" onClick={onClose}>
                  Cancel
                </Button>

                <Button
                  end-activity-button="true"
                  disabled={!isValid}
                  loading={isLoading}
                  onClick={() => submitForm()}
                >
                  {title}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
      <LoadingOverlay visible={activityTemplateLoading} />
    </Modal>
  );
};

const EndActivitySchema = (isGroupEvent = false) =>
  Yup.object({
    startedAt: Yup.date(),
    completedAt: Yup.date(),
    billable: Yup.boolean().optional(),
    billingDetails: Yup.mixed<BillingDetailsFormValues | undefined>().when(
      "billable",
      {
        is: true,
        then: BillingDetailsSchema(isGroupEvent)
          .strict(true)
          .required("Billing details is required"),
        otherwise: Yup.mixed().optional(),
      }
    ),
  }).concat(AppointmentDetailsSchema);

type EndActivityFormValues = Yup.InferType<
  ReturnType<typeof EndActivitySchema>
>;

type WrapFormValuesOptions = {
  activity: Activity;
  canBeBillable: boolean;
  setSelectedInsurance: (insurance: string | undefined) => void;
  memberDiagnoses?: string[];
  selectedMemberInsurance?: string;
  memberInsurance?: string;
};

const wrapFormValues = ({
  activity,
  canBeBillable,
  setSelectedInsurance,
  memberDiagnoses,
  selectedMemberInsurance,
  memberInsurance,
}: WrapFormValuesOptions): EndActivityFormValues => {
  let billingDetails = undefined;

  // when we are creating the initial values, use member's primary insurance
  if (selectedMemberInsurance === undefined)
    setSelectedInsurance(memberInsurance);

  if (canBeBillable && activity.billable) {
    billingDetails = activity.billingDetails
      ? wrapBillingDetails(
          activity.billingDetails,
          selectedMemberInsurance,
          memberInsurance
        )
      : {
          diagnosisCodes: undefined,
          procedure: undefined,
          placeOfService: undefined,
        };

    if (memberDiagnoses && memberDiagnoses.length > 0) {
      billingDetails.diagnosisCodes = memberDiagnoses.map((diagnosis) => ({
        label: diagnosis,
        value: diagnosis,
      }));
    }
  }

  const lastPing =
    activity.lastPing ??
    activity.scheduledEndAt ??
    dayjs(activity.date).subtract(activity.duration, "seconds").toISOString();

  return {
    startedAt: dayjs(lastPing).subtract(activity.duration, "seconds").toDate(),
    completedAt: new Date(lastPing),

    billable: canBeBillable && activity.billable,
    billingDetails: activity.billingDetails
      ? wrapBillingDetails(
          activity.billingDetails,
          selectedMemberInsurance,
          memberInsurance
        )
      : undefined,

    syncAppointment: activity.syncAppointment,
    appointmentDetails:
      activity.appointmentDetails as EndActivityFormValues["appointmentDetails"],
  };
};

const unwrapFormValues = (values: EndActivityFormValues): UpdateActivity => {
  const completedAt = dayjs(values.completedAt).toDate();
  const duration = dayjs(completedAt).diff(values.startedAt, "seconds");
  const date = dayjs(values.startedAt).toISOString();

  return {
    date,
    scheduledEndAt: completedAt.toISOString(),
    duration,
    lastPing: completedAt.toISOString(),
    billable: values.billable ?? false,
    billingDetails:
      values.billable && values.billingDetails
        ? unwrapBillingDetails(values.billingDetails)
        : undefined,
    syncAppointment: values.syncAppointment,
    appointmentDetails: unwrapAppointmentDetails(values.appointmentDetails),
  };
};
