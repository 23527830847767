import { gql } from "@apollo/client";

// This function is used to generate the fields for the builder node
// based on the number of levels specified.
// Since graphql does not allow recursive fragments
const getBuilderNodeFields = (levels = 10): string =>
  levels === 0
    ? `__typename`
    : `
        ... on BuilderBasicNode {
          id
          title
          type
          children {
            ${getBuilderNodeFields(levels - 1)}
          }
        }

        ... on BuilderCircularNode {
          id
        }

        __typename
      `;

export const GET_BUILDER_GRAPH = gql`
  query GetBuilderGraph(
    $id: ID!,
    $organizationId: ID!,
    $type: BuilderNodeType!
  ) {
    builderGraph(
      id: $id, 
      organizationId: $organizationId,
      type: $type
    ) {
      success
      message
      data {
        id
        title
        type
        children {
          ${getBuilderNodeFields()}
        }
      }
    }
  }
`;
