import { Box, Group, Stack, Switch } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { SectionHeader } from "src/components";
import { Note } from "src/graphql";
import { Notes, useAuthContext } from "src/hooks";

type MemberNotesProps = {
  memberId: string;
  multiMemberActivity?: boolean;
  activityId?: string;
  hideTitle?: boolean;
  style?: React.CSSProperties;
  filterNotes?: (notes: Note[], viewAllNotes: boolean) => Note[];
  readonly?: boolean;
  episodeOfCareId?: string;
};

export const MemberNotes = ({
  hideTitle = false,
  memberId,
  activityId,
  style,
  multiMemberActivity,
  filterNotes,
  readonly = false,
  episodeOfCareId,
}: MemberNotesProps) => {
  const { memberPageReadOnly } = useAuthContext();
  const [viewAllNotes, setViewAllNotes] = useToggle();

  return (
    <Stack spacing={0} style={style}>
      {!hideTitle && (
        <SectionHeader noGap>
          <Group>
            <span>Member Notes</span>
            {activityId && (
              <Switch
                ml="auto"
                label="View all"
                size="xs"
                checked={viewAllNotes}
                onChange={(e) => setViewAllNotes(e.currentTarget.checked)}
              />
            )}
          </Group>
        </SectionHeader>
      )}

      <Box p="xs" h="100%" style={{ overflowY: "auto" }}>
        <Notes
          listOptions={{
            memberId,
            activityId: viewAllNotes ? undefined : activityId,
            episodeOfCareId: episodeOfCareId ?? undefined,
          }}
          hideAddButton={memberPageReadOnly}
          createOptions={{ memberId, activityId }}
          multiMemberActivity={multiMemberActivity}
          readonly={readonly}
        />
      </Box>
    </Stack>
  );
};
