import { ApolloQueryResult, DocumentNode, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { DataTableComponentProps } from "src/components";
import { PaginationInput } from "src/graphql";
import log from "loglevel";
import { useDebouncedValue, useToggle } from "@mantine/hooks";

interface PaginationHookValue<
  T extends { _id: string },
  Response extends { response: { data?: PaginatedQueryResult<T> } }
> {
  /**
   * true before first time data loads
   */
  initialized: boolean;
  /**
   * true any time data is loading (e.g. filter or page change)
   */
  loading: boolean;
  data?: Response["response"]["data"];
  searchTerm: string;
  setSearchTerm: (nextTerm: string) => void;
  setGroups: (groups?: string[]) => void;
  refetch: () => Promise<ApolloQueryResult<Response>>;
  dataTableProperties: Partial<DataTableComponentProps<T>> & { data: T[] };
}

export type MongoSort = {
  field: string;
  direction: 1 | -1;
};

export interface PaginatedQueryResult<T extends { _id: string }> {
  data: T[];
  total: number;
  page: number;
}

type UsePaginationArgs<T> = {
  document: DocumentNode;
  variables: Omit<T, "pagination">;
  options?: {
    defaultSort?: MongoSort;
    size?: number;
  };
};

export const usePagination = <
  Variables extends { pagination: PaginationInput },
  Response extends { response: { data?: PaginatedQueryResult<T> } },
  T extends { _id: string }
>({
  document,
  variables,
  options,
}: UsePaginationArgs<Variables>): PaginationHookValue<T, Response> => {
  const [size, setSize] = useState(options?.size ?? 10);
  const [total, setTotal] = useState(0);
  const [searchAndPage, setSearchAndPage] = useState({ search: "", page: 1 });
  const [paginationToggle, resetPaginationToggle] = useToggle();
  const [useLeading, setUseLeading] = useState(false);
  const [debouncedValues] = useDebouncedValue(searchAndPage, 300, {
    leading: useLeading,
  });
  const [sort, setSort] = useState<MongoSort | undefined>(
    options?.defaultSort ?? undefined
  );

  // response state
  const [data, setData] = useState<T[]>([]);
  const [initialized, setInitialized] = useState(false);

  const {
    data: response,
    loading,
    refetch,
  } = useQuery<Response, Variables>(document, {
    fetchPolicy: "no-cache",
    variables: {
      ...variables,
      pagination: {
        size,
        page: debouncedValues.page,
        sort,
        search: debouncedValues.search,
      },
    } as Variables,
  });

  useEffect(() => {
    const result = response?.response.data;
    if (result && !loading) {
      setInitialized(true);
      setTotal(result.total);
      setData(result.data);
    }
  }, [response, loading]);

  const handleSetSearchTerm = (nextTerm: string) => {
    resetPaginationToggle();
    if (useLeading) setUseLeading(false);
    requestAnimationFrame(() => {
      setSearchAndPage((values) => ({ ...values, search: nextTerm }));
    });
  };

  const handleSetGroups = (groups?: string[]) => {
    resetPaginationToggle();
    if (useLeading) setUseLeading(false);
    requestAnimationFrame(() => {
      setSearchAndPage((values) => ({ ...values, groups }));
    });
  };

  return {
    initialized,
    loading,
    data: response?.response.data,
    searchTerm: searchAndPage.search,
    setSearchTerm: handleSetSearchTerm,
    setGroups: handleSetGroups,
    refetch,

    dataTableProperties: {
      data,
      sortServer: true,
      paginationServer: true,
      paginationTotalRows: total,
      initialized,
      refreshingData: loading,
      defaultSortFieldId: sort?.field,
      defaultSortAsc: sort?.direction !== -1,
      paginationResetDefaultPage: paginationToggle,

      onSort(selectedColumn, sortDirection) {
        if (
          typeof selectedColumn.name !== "string" &&
          typeof selectedColumn.sortField !== "string"
        )
          return log.error(
            new Error("Expected string name or sort field property on column")
          );

        setSort({
          field: selectedColumn.sortField || (selectedColumn.name as string),
          direction: sortDirection === "asc" ? 1 : -1,
        });
      },

      onChangePage(page) {
        if (!useLeading) setUseLeading(true);
        setSearchAndPage((values) => ({ ...values, page }));
      },

      onChangeRowsPerPage(size) {
        setSize(size);
      },
    },
  };
};
