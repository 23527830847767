import { GroupFields } from "../MemberGroup/types";

export const regionFields = `
  value
  label
`;
export const memberSearchInputField = `
  firstName
  lastName
  nickName
  dob
  pronouns
  race
  ethnicity
  spokenLanguages
  sex
  gender
  sexualOrientation
  medicareStatus
  medicaidStatus
  contactInfo {
    organization
    address
    address2
    email
    country
    state
    zip
    city
    phone
  }
  timezone
  primaryCIN
  primaryInsuranceCompany
  secondaryCIN
  secondaryInsuranceCompany
`;

const memberInputField = `
  firstName
  lastName
  nickName
  phonetic
  dob
  pronouns
  race
  ethnicity
  spokenLanguages
  sex
  gender
  sexualOrientation
  medicareStatus
  medicaidStatus
  diagnoses
  prescriptions
  pregnancyStatus
  socialSecurityNumber
  familyMembers {
    memberId
    familyMemberName
    familyLinkType
  }
  
  primaryCIN
  primaryInsuranceCompany
  secondaryCIN
  secondaryInsuranceCompany
  
  contactInfo {
    organization
    groups {
      joinedAt
      ${GroupFields}
    }
    address
    address2
    email
    country
    state
    zip
    city
    phone
    doNotCall
  }
  timezone
  ehrID
  ehrIDType
`;

export const memberFields = `
  _id
  ${memberInputField}
  organizationName
  lastEhrSync
  createdAt
  linkedContacts{
    _id
    name
    email
    phone
    relation
    type
    canBeCalled
    canBeTexted
  }
  assignedUserIds
  diagnosisCodes {
    code
    description
  }
  overriddenProcedureCodeLimits
  status
  reasonForDeactivation

  lastCoverageUpdate
  episodesOfCare {
    memberId
    _id
  }
  providerRecommendation {
    _id
    createdAt
    updatedAt
    status
    memberId
    batchId
    notes
    batch {
      _id
      createdAt
      updatedAt
      templateId
      signatureRequestId
      recommendingProvider {
        _id
        firstName
        lastName
        nationalProviderIdentifier
        title
        faxNumber
        companyName
      }
    }
  }
  insuranceCoverages {
    _id
    availityExpirationDate
    availityUpdatedDate
    customerId
    cardIssueDate
    asOfDate
    toDate
    procedureCodes
    subscriberMedicaidId
    subscriberMemberId
    subscriberFirstName
    subscriberLastName
    subscriberMiddleName
    patientFirstName
    patientLastName
    patientMiddleName
    patientBirthDate
    patientGender
    subscriberRelationship
    payerId
    payerName

    requestedServiceType {
      code
      value
    }

    plans {
      _id
      status
      statusCode
      groupNumber
      groupName
      eligibilityStartDate
      eligibilityEndDate
      coverageStartDate
      coverageEndDate
      insuranceType

      additionalPayers {
        name
        type
        insuredId
      }
    }
  }
`;

export const memberStatFields = `
  _id
  name
  memberId
  status
  groups
  assignedUsers
  numGoals
  numCompletedGoals
  numScheduledActivities
  numCompletedActivities
  completedGoalDate
  nextActivityDate
  prevActivityDate
`;

export const unassignedMemberFields = `
  _id
  firstName
  lastName
  status
  groups
`;

export const droppedMemberFields = `
  _id
  firstName
  lastName
  nickName
  phonetic
  status
  lastActivity
  groups
  users
`;
