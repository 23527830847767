import { EventFields } from "../Event/types";

export const ActivityFields = `
    _id
    createdAt
    activityTemplateId 
    title
    description
    duration
    lastPing
    date
    scheduledEndAt
    status
    userId 
    userName
    lastStartedAt
    members {
        memberId
        memberDisplayName
    }
    organizationId
    sync
    
    billable
    billingDetails {
        placeOfService
        procedure
        diagnosisCodes
        modifiers
        billingSummary {
            insurance
            costPerUnit
            billableUnits
            billableAmount
        }
    }

    syncAppointment
    appointmentDetails {
        locationType
        locationFacility
        locationDepartment
        locationRoom
    }

    tasks {
        id
        type
        status
        statusMessage
        formId
        description
        flowTemplateTitle
        flowId
        event {
            ${EventFields}
        }
    }
`;

export const ActivityFieldsWithAlias = `
_id
    createdAt
    activityTemplateId 
    title
    description
    duration
    lastPing
    date
    scheduledEndAt
    activityStatus: status
    userId 
    userName
    lastStartedAt
    members {
        memberId
        memberDisplayName
    }
    activityOrganizationId: organizationId 
    sync
    
    billable
    billingDetails {
        placeOfService
        procedure
        diagnosisCodes
        modifiers
        billingSummary {
            insurance
            costPerUnit
            billableUnits
            billableAmount
        }
    }

    syncAppointment
    appointmentDetails {
        locationType
        locationFacility
        locationDepartment
        locationRoom
    }

    tasks {
        id
        type
        status
        statusMessage
        formId
        description
        flowTemplateTitle
        flowId
        event {
            ${EventFields}
        }
    }
`;
