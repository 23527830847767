import { gql } from "@apollo/client";
import { memberFields } from "../Members/types";

export const GET_GROUP_MEMBERS = gql`
  query GroupMembers($groupId: ID!, $organizationId: ID!) {
    groupMembers(groupId: $groupId, organizationId: $organizationId) {
      success
      message
      data {
        ${memberFields}
      }
    }
  }
`;
