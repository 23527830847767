import gql from "graphql-tag";

export const GET_SIGNED_DOCUMENT = gql`
  query GetSignedDocument($signatureRequestId: ID!) {
    signedDocument(signatureRequestId: $signatureRequestId) {
      success
      data {
        url
      }
      message
    }
  }
`;
