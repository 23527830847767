import { useMutation, useQuery } from "@apollo/client";
import { GET_BUILDER_GRAPH } from "../Builder/queries";
import {
  EmailTemplate,
  GetEmailTemplateResponse,
  GetEmailTemplatesResponse,
  MutationCopyEmailTemplateArgs,
  MutationCreateEmailTemplateArgs,
  MutationDeleteEmailTemplateArgs,
  MutationUpdateEmailTemplateArgs,
  QueryEmailTemplateArgs,
  QueryEmailTemplatesArgs,
} from "../schemaTypes";
import {
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  COPY_EMAIL_TEMPLATE,
} from "./mutations";
import {
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_META,
} from "./queries";

export const useQueryEmailTemplates = (organizationId: string) =>
  useQuery<
    {
      emailTemplates: GetEmailTemplatesResponse;
    },
    QueryEmailTemplatesArgs
  >(GET_EMAIL_TEMPLATES, {
    variables: { organizationId },
  });

export type GetEmailtemplatesMetaResponse = Omit<
  GetEmailTemplatesResponse,
  "data"
> & {
  data: Omit<EmailTemplate, "body">[] | undefined;
};
export const useQueryEmailTemplatesMeta = (organizationId: string) =>
  useQuery<
    {
      emailTemplates: GetEmailtemplatesMetaResponse;
    },
    QueryEmailTemplatesArgs
  >(GET_EMAIL_TEMPLATES_META, {
    variables: { organizationId },
  });

export const useQueryEmailTemplate = (organizationId: string, id?: string) =>
  useQuery<
    {
      emailTemplate: GetEmailTemplateResponse;
    },
    QueryEmailTemplateArgs
  >(GET_EMAIL_TEMPLATE, {
    skip: !id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { input: { organizationId, _id: id! } },
  });

export const useMutationCreateEmailTemplate = (organizationId: string) =>
  useMutation<
    {
      createEmailTemplate: GetEmailTemplateResponse;
    },
    MutationCreateEmailTemplateArgs
  >(CREATE_EMAIL_TEMPLATE, {
    update: (cache, { data }) => {
      if (
        !data ||
        !data.createEmailTemplate.success ||
        !data.createEmailTemplate.data
      )
        return;

      const newEmailTemplate = data.createEmailTemplate.data;

      const emailTemplatesQuery = cache.readQuery<
        { emailTemplates: GetEmailTemplatesResponse },
        QueryEmailTemplatesArgs
      >({
        query: GET_EMAIL_TEMPLATES,
        variables: { organizationId },
      });

      if (
        !emailTemplatesQuery ||
        !emailTemplatesQuery.emailTemplates ||
        !emailTemplatesQuery.emailTemplates.success ||
        !emailTemplatesQuery.emailTemplates.data
      )
        return;

      const emailTemplates = [
        ...emailTemplatesQuery.emailTemplates.data,
        newEmailTemplate,
      ];

      cache.writeQuery<
        { emailTemplates: GetEmailTemplatesResponse },
        { organizationId: string }
      >({
        query: GET_EMAIL_TEMPLATES,
        variables: { organizationId },
        data: {
          emailTemplates: {
            ...emailTemplatesQuery.emailTemplates,
            data: emailTemplates,
          },
        },
      });
    },

    refetchQueries: [GET_BUILDER_GRAPH],
  });

export const useMutationUpdateEmailTemplate = (organizationId: string) =>
  useMutation<
    {
      updateEmailTemplate: GetEmailTemplateResponse;
    },
    MutationUpdateEmailTemplateArgs
  >(UPDATE_EMAIL_TEMPLATE, {
    refetchQueries: [GET_EMAIL_TEMPLATES, GET_BUILDER_GRAPH],
  });

export const useMutationDeleteEmailTemplate = (organizationId: string) =>
  useMutation<
    {
      deleteEmailTemplate: GetEmailTemplateResponse;
    },
    MutationDeleteEmailTemplateArgs
  >(DELETE_EMAIL_TEMPLATE, {
    update: (cache, { data }) => {
      const emailTemplatesQuery = cache.readQuery<
        { emailTemplates: GetEmailTemplatesResponse },
        QueryEmailTemplatesArgs
      >({
        query: GET_EMAIL_TEMPLATES,
        variables: { organizationId },
      });

      if (
        !data ||
        !data.deleteEmailTemplate.success ||
        !data.deleteEmailTemplate.data ||
        !emailTemplatesQuery ||
        !emailTemplatesQuery.emailTemplates ||
        !emailTemplatesQuery.emailTemplates.success ||
        !emailTemplatesQuery.emailTemplates.data
      )
        return;

      const emailTemplates = emailTemplatesQuery.emailTemplates.data.filter(
        (emailTemplate) =>
          emailTemplate._id !== data.deleteEmailTemplate.data?._id
      );

      cache.writeQuery<
        { emailTemplates: GetEmailTemplatesResponse },
        { organizationId: string }
      >({
        query: GET_EMAIL_TEMPLATES,
        variables: { organizationId },
        data: {
          emailTemplates: {
            ...emailTemplatesQuery.emailTemplates,
            data: emailTemplates,
          },
        },
      });
    },

    refetchQueries: [GET_BUILDER_GRAPH],
  });

export const useMutationCopyEmailTemplate = (organizationId: string) =>
  useMutation<
    { copyEmailTemplate: GetEmailTemplateResponse },
    MutationCopyEmailTemplateArgs
  >(COPY_EMAIL_TEMPLATE, {
    update: (cache, { data }) => {
      if (
        !data ||
        !data.copyEmailTemplate.success ||
        !data.copyEmailTemplate.data
      )
        return;

      const newEmailTemplate = data.copyEmailTemplate.data;

      const emailTemplatesQuery = cache.readQuery<
        { emailTemplates: GetEmailTemplatesResponse },
        QueryEmailTemplatesArgs
      >({
        query: GET_EMAIL_TEMPLATES,
        variables: { organizationId },
      });

      if (
        !emailTemplatesQuery ||
        !emailTemplatesQuery.emailTemplates ||
        !emailTemplatesQuery.emailTemplates.success ||
        !emailTemplatesQuery.emailTemplates.data
      )
        return;

      const emailTemplates = [
        ...emailTemplatesQuery.emailTemplates.data,
        newEmailTemplate,
      ];

      cache.writeQuery<
        { emailTemplates: GetEmailTemplatesResponse },
        { organizationId: string }
      >({
        query: GET_EMAIL_TEMPLATES,
        variables: { organizationId },
        data: {
          emailTemplates: {
            ...emailTemplatesQuery.emailTemplates,
            data: emailTemplates,
          },
        },
      });
    },

    refetchQueries: [GET_BUILDER_GRAPH],
  });
