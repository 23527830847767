import { gql } from "@apollo/client";
import { OrganizationFields } from "../Organization/types";
import { UserFields, UserStatFields } from "./types";

export const GET_USERS = gql`
  query GetUsers($input: GetUsersInput!) {
    users(input: $input){
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($userId: ID) {
    user(userId: $userId){
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;

export const GET_USER_AND_ORGS = gql`
  query GetUserAndOrgs($userId: ID!) {
    user(userId: $userId) {
      success
      message
      data {
        ${UserFields}
      }
    }
    organizationsForUser(userId: $userId) {
      success
      message
      data {
        ${OrganizationFields}
      }
    }
  }
`;

export const GET_USERS_STATS = gql`
  query GetUserStats($organization: ID, $getDeactivatedUsers: Boolean, $before: String, $after: String) {
    userStats(organization: $organization, getDeactivatedUsers: $getDeactivatedUsers, before: $before, after: $after) {
      success
      message
      data {
        ${UserStatFields}
      }
    }
  }
`;

export const GET_AVAILABLE_USERS_BY_ORG_ID = gql`
  query GetAvailableUsersByOrgId($organization: ID!) {
    availableUsersByOrganizationId(organization: $organization) {
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;

export const GET_RECOMMENDING_PROVIDERS_BY_ORG_ID = gql`
  query GetRecommendingProvidersByOrgId($input: GetRecommendingProvidersInput!) {
    recommendingProvidersByOrganizationId(input: $input){
      success
      message
      data {
        ${UserFields}
      }
    }
  }
`;
