import {
  ActivityBillingDetailsMeta,
  ActivityTemplate,
  UpdateActivityBillingDetails,
} from "src/graphql";
import { ySelectOptionSchema } from "src/utils";
import * as Yup from "yup";
import { FormikMultiSelect, FormikSelect, StyledLabel } from "../input";
import { useAuthContext } from "src/hooks";
import { useEffect, useMemo, useState } from "react";
import { placeOfServiceCodeOptions } from "src/pages/organization/PlaceOfServiceCodesSection";
import { SelectOption } from "src/types";
import { Badge, Flex, Group, Stack, Text } from "@mantine/core";
import { Disclaimer } from "../flow-graph/template-editor/add-action-modal/shared";
import { isEqual } from "lodash";

export const BillingDetailsSchema = (isGroupEvent = false) =>
  Yup.object({
    insurance: !isGroupEvent
      ? ySelectOptionSchema(Yup.string()).strict(true)
      : ySelectOptionSchema(Yup.string()).strict(true).optional(),

    procedure: ySelectOptionSchema(Yup.string()).strict(true),

    diagnosisCodes: !isGroupEvent
      ? Yup.array(
          ySelectOptionSchema(Yup.string().required()).strict(true)
        ).required("Diagnosis code is required")
      : Yup.array(
          ySelectOptionSchema(Yup.string().required()).strict(true)
        ).optional(),

    placeOfService: ySelectOptionSchema(Yup.string().required())
      .strict(true)
      .required("Place of service is required"),
  });

export type BillingDetailsFormValues = Yup.InferType<
  ReturnType<typeof BillingDetailsSchema>
>;

type BillingDetailsFieldsProps = {
  fieldsPrefix: string;
  values: BillingDetailsFormValues | undefined;
  setFieldValue: (
    field: string,
    value:
      | {
          label: string;
          value: string;
        }
      | {
          label: string;
          value: string;
        }[]
      | undefined
  ) => void;
  selectedInsurance: string | undefined;
  setSelectedInsurance: (insurance: string | undefined) => void;
  isForGroupEvent?: boolean;
  activityTemplate?: ActivityTemplate;
  preFillBillingFields: boolean;
};

export const BillingDetailsFields = ({
  fieldsPrefix,
  values,
  setFieldValue,
  selectedInsurance,
  setSelectedInsurance,
  isForGroupEvent,
  preFillBillingFields,
  activityTemplate,
}: BillingDetailsFieldsProps) => {
  const { selectedOrganization } = useAuthContext();
  const [modifiers, setModifiers] = useState<string[]>(
    values?.procedure?.value !== undefined
      ? JSON.parse(values?.procedure.value).modifiers ?? []
      : []
  );
  const diagnosisOptions = useMemo(
    () =>
      selectedOrganization.diagnosisCodes.map(({ code, description }) => ({
        label: `${code} - ${description ?? "No description"}`,
        value: code,
      })),
    [selectedOrganization.diagnosisCodes]
  );
  const insuranceOptions = useMemo(() => {
    return selectedOrganization.insuranceCompanies
      .filter((insurance) => insurance === selectedInsurance)
      .map((insurance) => ({
        label: insurance,
        value: insurance,
      }));
  }, [selectedOrganization.insuranceCompanies, selectedInsurance]);

  const procedureOptions = useMemo(() => {
    return (
      selectedOrganization.billingConfiguration?.insuranceConfigurations
        .find(
          (insuranceConfig) => insuranceConfig.insurance === selectedInsurance
        )
        ?.costs.map((config) => ({
          label: getProcedureLabel(config.procedure, config.modifiers),
          value: JSON.stringify({
            procedure: config.procedure,
            modifiers: config.modifiers,
          }),
        })) ?? []
    );
  }, [
    selectedOrganization.billingConfiguration?.insuranceConfigurations,
    selectedInsurance,
  ]);

  const organizationPlaceOfServiceOptions = useMemo(
    () =>
      placeOfServiceCodeOptions.filter((option) =>
        selectedOrganization.placeOfServiceCodes.includes(
          parseInt(option.value, 10)
        )
      ),
    [selectedOrganization.placeOfServiceCodes]
  );

  const selectedProcedure = useMemo(() => {
    if (!values?.procedure?.value) {
      return undefined;
    }

    const selectedProcedureJSON = JSON.parse(values.procedure.value);
    const selectedProcedure = selectedProcedureJSON?.procedure;
    const selectedProcedureModifiers = selectedProcedureJSON?.modifiers ?? [];

    const orgProcedure =
      selectedOrganization?.billingConfiguration?.insuranceConfigurations
        .find(
          (insuranceConfig) => insuranceConfig.insurance === selectedInsurance
        )
        ?.costs?.find((cost) => {
          const costModifiers = cost?.modifiers ?? [];

          return (
            cost?.procedure === selectedProcedure &&
            isEqual(costModifiers, selectedProcedureModifiers?.sort())
          );
        });

    return orgProcedure;
  }, [
    selectedInsurance,
    selectedOrganization?.billingConfiguration?.insuranceConfigurations,
    values?.procedure,
  ]);

  // update billing details based on default values on activity template
  // look through options and if they can be found then update the fields
  // otherwise if nothing can be found in options above we shouldn't force update the fields
  useEffect(() => {
    if (!preFillBillingFields) {
      return;
    }

    // the modal is used in different places sometime is being always mounted sometime is mounted everytime is opened
    // there are cases when formik is not validating always fields this causing End Activity btn to be disabled so having a small timeout to set fields after formik-related stuff is mounted
    setTimeout(() => {
      const defaultBillingDetails = activityTemplate?.defaultBillingDetails;
      if (!defaultBillingDetails) {
        return;
      }

      if (selectedInsurance) {
        setFieldValue(`${fieldsPrefix}.insurance`, {
          value: selectedInsurance,
          label: selectedInsurance,
        });
      }

      const procedure = procedureOptions.find(
        (procedure) =>
          (procedure.label ?? "").split(" -")[0] ===
          defaultBillingDetails.procedure
      );

      if (procedure && procedure.value !== values?.procedure?.value) {
        setFieldValue(`${fieldsPrefix}.procedure`, procedure);
        setModifiers(JSON.parse(procedure?.value).modifiers ?? []);
      }

      const placeOfService = placeOfServiceCodeOptions.find(
        (pos) => pos.value === defaultBillingDetails.placeOfService
      );
      if (
        placeOfService &&
        placeOfService.value !== values?.placeOfService?.value
      ) {
        setFieldValue(`${fieldsPrefix}.placeOfService`, placeOfService);
      }

      const diagnosisCodes = diagnosisOptions.filter((diagnosisCode) =>
        defaultBillingDetails.diagnosisCodes?.includes(diagnosisCode.value)
      );
      const valuesDiagnosisCode = values?.diagnosisCodes?.map((v) => v.value);
      const foundDiagnosisCodes = diagnosisCodes.map((v) => v.value);

      if (
        diagnosisCodes?.length &&
        !isEqual(valuesDiagnosisCode?.sort(), foundDiagnosisCodes.sort())
      ) {
        setFieldValue(`${fieldsPrefix}.diagnosisCodes`, diagnosisCodes);
      }
    }, 50);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormikSelect
        name={`${fieldsPrefix}.insurance`}
        label="Insurance Company"
        options={insuranceOptions}
        onChangeOverride={(option) => {
          setFieldValue(`${fieldsPrefix}.insurance`, option ?? undefined);
          setFieldValue(`${fieldsPrefix}.procedure`, undefined);
          setSelectedInsurance(option?.value);
        }}
      />
      <FormikSelect
        name={`${fieldsPrefix}.procedure`}
        label="Procedure"
        options={procedureOptions}
        onChangeOverride={(option) => {
          setFieldValue(`${fieldsPrefix}.procedure`, option ?? undefined);
          if (option?.value !== undefined) {
            const value = JSON.parse(option?.value);
            setModifiers(value.modifiers ?? []);
          }
        }}
      />
      {values?.procedure?.label !== undefined && (
        <Stack spacing={0}>
          <StyledLabel>Description</StyledLabel>
          {selectedProcedure?.description ? (
            <Text color="gray">{selectedProcedure.description}</Text>
          ) : (
            <Text color="gray">-</Text>
          )}
        </Stack>
      )}
      {values?.procedure?.label !== undefined && (
        <Flex>
          <Stack spacing={0}>
            <StyledLabel>Modifiers</StyledLabel>
            <Group spacing="xs">
              {modifiers.length > 0 ? (
                modifiers
                  .filter((modifier: string) => modifier.length > 0)
                  .map((modifier: string) => (
                    <Badge key={modifier + values?.procedure.label}>
                      {modifier}
                    </Badge>
                  ))
              ) : (
                <Text color="gray">No modifiers to display</Text>
              )}
            </Group>
          </Stack>
        </Flex>
      )}

      <FormikMultiSelect
        required
        name={`${fieldsPrefix}.diagnosisCodes`}
        label="Diagnosis"
        options={diagnosisOptions}
      />
      <FormikSelect
        required
        name={`${fieldsPrefix}.placeOfService`}
        label="Place of Service"
        options={organizationPlaceOfServiceOptions}
      />
      {selectedProcedure?.recommendingProviderRequired === true &&
        selectedOrganization.billingConfiguration
          ?.recommendingProviderEnabled && (
          <Disclaimer>
            Note: This billable activity requires a{" "}
            <strong>recommendation from a Healthcare Provider.</strong> A
            request will be sent to the provider to provide this recommendation.
          </Disclaimer>
        )}
    </>
  );
};

export const wrapBillingDetails = (
  values: ActivityBillingDetailsMeta,
  selectedMemberInsurance: string | undefined,
  memberInsurance: string | undefined
): BillingDetailsFormValues => {
  return {
    insurance: {
      label: selectedMemberInsurance ?? memberInsurance ?? "",
      value: selectedMemberInsurance ?? memberInsurance ?? "",
    },
    placeOfService: placeOfServiceCodeOptions.find(
      (option) => parseInt(option.value, 10) === values.placeOfService
    ) as SelectOption<string>,
    procedure: {
      label: getProcedureLabel(values.procedure, values.modifiers),
      value: JSON.stringify({
        procedure: values.procedure,
        modifiers: values.modifiers,
      }),
    },
    diagnosisCodes: values.diagnosisCodes.map((value) => ({
      label: value,
      value,
    })),
  };
};

export const unwrapBillingDetails = (
  values: BillingDetailsFormValues
): UpdateActivityBillingDetails => {
  const placeOfService = parseInt(values.placeOfService.value, 10);
  if (isNaN(placeOfService)) throw new Error("Place of service is required");
  const costConfig =
    values.procedure?.value !== undefined
      ? JSON.parse(values.procedure.value)
      : undefined;
  return {
    procedure: costConfig !== undefined ? costConfig.procedure : "",
    diagnosisCodes: values.diagnosisCodes?.map((code) => code.value) ?? [],
    placeOfService,
    modifiers:
      costConfig !== undefined && costConfig.modifiers
        ? costConfig.modifiers
        : [],
  };
};

export const getProcedureLabel = (
  procedure: string,
  modifiers: string[]
): string => {
  return `${procedure} - ${
    modifiers && modifiers.length > 0 ? modifiers.join(", ") : "No modifiers"
  }`;
};
