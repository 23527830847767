export const actionsFields = `
  createGoal {
    goalTemplateId
    id
  }
  updateGoal {
    goalTemplateId
    status
    id
  }
  scheduleActivity {
    activityTemplateId
    id
  }
  updateMember {
    field
    value {
      constant
      input
    }
    id
  }
  createReferral {
    externalResourceId
    referralTemplateId
    type
    id
  }
  addMemberToGroup {
    groupId
    id
  }
  removeMemberFromGroup {
    groupId
    id
  }
  startCarePathway {
    carePathwayTemplateId
    id
  }

  updateCarePathwayStatus {
    carePathwayTemplateId
    status
    id
  }

  createConsent {
    consentTemplateId
    id
  }
`;

export const recommendationsFields = `
_id
name
recommendationText
updatedAt
createdAt
organizationId
actions {
  ${actionsFields}
}
conditionsGroups {
  _id
  conditionOperator
  conditions {
    _id
    operator
    conditionOperator
    value
    dataId
  }
}
generatedForMemberAt
`;
