import { ActionIcon, Button, Text, Tooltip } from "@mantine/core";
import { TableColumn } from "react-data-table-component";
import { Edit, Trash2 } from "react-feather";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "src/components";

export const BillingStates = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

type EditDeleteSelectorColumnProps<T> = {
  onEdit: (idx: number, value: T) => void;
  onDelete: (idx: number, value: T) => void;
  disable?: (value: T) => boolean;
};

export const createEditDeleteSelectorColumns = <T extends unknown>({
  onEdit,
  onDelete,
  disable,
}: EditDeleteSelectorColumnProps<T>): TableColumn<T>[] => [
  {
    name: "Edit",
    grow: 0,
    center: true,
    minWidth: "60px",
    compact: true,
    cell: (value, idx) => (
      <Tooltip label="Edit" disabled={disable ? disable(value) : false}>
        <ActionIcon
          edit-icon="true"
          color="blue"
          variant="transparent"
          onClick={() => onEdit(idx, value)}
          disabled={disable ? disable(value) : false}
          aria-label="Edit"
        >
          <Edit height="16" />
        </ActionIcon>
      </Tooltip>
    ),
  },
  {
    name: "Delete",
    grow: 0,
    center: true,
    minWidth: "60px",
    compact: true,
    cell: (value, idx) => (
      <Tooltip label="Delete">
        <ActionIcon
          color="red"
          variant="transparent"
          onClick={() => onDelete(idx, value)}
          aria-label="Delete"
        >
          <Trash2 height="16" />
        </ActionIcon>
      </Tooltip>
    ),
  },
];

export const DeleteConfirmationModal = <T extends unknown>({
  selected,
  description,
  onSave,
  onClose,
  isLoading,
}: {
  selected: T | null;
  description: (value: T) => string;
  onSave: (value: T) => void;
  onClose: () => void;
  isLoading?: boolean;
}) => {
  if (selected === null) return null;

  const handleSave = () => onSave(selected);

  return (
    <Modal opened onClose={onClose}>
      <ModalHeader>Confirm deletion</ModalHeader>

      <ModalBody>
        <Text>{description(selected) || ""}</Text>
      </ModalBody>

      <ModalFooter>
        <Button
          variant="outline"
          color="red"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </Button>

        <Button color="red" onClick={handleSave} loading={isLoading}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
