import {
  Button,
  Checkbox,
  Select,
  Box,
  Text,
  Flex,
  Group,
  Grid,
} from "@mantine/core";
import { FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef, useState, useEffect } from "react";

import {
  GoalTemplateForm,
  GoalTemplateFormValues,
  makeBaseGoalTemplate,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from "src/components";
import {
  GoalTemplate,
  useMutationCreateGoal,
  useQueryGoalTemplates,
} from "src/graphql";
import { BASE_CREATE_TEMPLATE_ID } from "src/pages/templates";
import { TemplateFormContext } from "../form";
import { QuestionTooltip } from "../question-tooltip";
import { SelectInterventionDropdown } from "./SelectInterventionDropdown";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";

type CreateGoalModalProps = {
  isOpen: boolean;
  organizationId: string;
  memberId: string;
  userId: string;
  activityId?: string;
  multiMemberActivity?: boolean;
  episodeOfCareId?: string;
  /**
   * Optional hook to run when the modal requests to close
   */
  onRequestClose?: () => void;
  /**
   * Optional hook to run after the modal successfully updates a template
   */
  afterUpdateSuccess?: () => void;
};

export const CreateGoalModal = ({
  isOpen,
  memberId,
  organizationId,
  userId,
  activityId,
  multiMemberActivity,
  episodeOfCareId,
  onRequestClose,
  afterUpdateSuccess,
}: CreateGoalModalProps) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [submissionPending, setSubmissionPending] = useState(false);
  const formikRef = useRef<FormikProps<GoalTemplateFormValues>>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    noTemplateOption.value
  );
  const [createForAllMembersInActivity, setCreateForAllMembersInActivity] =
    useState(false);

  const [selectedData, setSelectedData] = useState<any[]>([]); // eslint-disable-line @typescript-eslint/no-explicit-any

  const [selectedDueDate, setSelectedDueDate] = useState<Date | null>(null);
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null);

  // fetch templates
  const { loading: templatesLoading, data: templatesResponse } =
    useQueryGoalTemplates(organizationId);

  // mutation fns
  const [mutationCreateGoal] = useMutationCreateGoal(memberId, organizationId);

  // prepare values for form/local state
  const { templateOptions, templatesById } = useMemo(
    () => ({
      templateOptions: wrapGroupedTemplateOptions(
        templatesResponse?.goalTemplates.data ?? []
      ),
      templatesById: [
        makeBaseGoalTemplate(organizationId),
        ...(templatesResponse?.goalTemplates.data ?? []),
      ]?.reduce(
        (byId, template) => ({
          ...byId,
          [template._id]: template,
        }),
        {} as Record<string, GoalTemplate>
      ),
    }),
    [templatesResponse?.goalTemplates.data, organizationId]
  );

  const selectedTemplate = templatesById[selectedTemplateId];

  useEffect(() => {
    if (selectedTemplate?.domainId) {
      setSelectedDomain(selectedTemplate.domainId);
    } else {
      setSelectedDomain(null);
    }
  }, [selectedTemplate]);

  // GoalTemplateForm submit handler
  const handleSubmit = async (
    template: GoalTemplate,
    formikHelpers: FormikHelpers<GoalTemplateFormValues>
  ) => {
    formikHelpers.setStatus({});
    const formikApi = formikRef.current;
    if (!formikApi)
      throw new Error(
        "Formik ref not populated, check CreateGoalModal component definition"
      );

    setSubmissionPending(true);

    try {
      const { _id, lockingReferences, ...templateInput } = template;
      const res = await mutationCreateGoal({
        variables: {
          input: {
            memberId,
            dueDate: selectedDueDate?.toISOString() ?? undefined,
            template: templateInput,
            episodeOfCareId,
            baseTemplateId:
              template._id === BASE_CREATE_TEMPLATE_ID
                ? undefined
                : template._id,
            createForAllMembersInActivity,
            activityId: activityId,
            activityIds: selectedData
              .filter((data) => data.__typename === "Activity")
              .map((data) => data._id),
            referralIds: selectedData
              .filter((data) => data.__typename === "Referral")
              .map((data) => data._id),
            carePathwayIds: selectedData
              .filter((data) => data.__typename === "CarePathway")
              .map((data) => data._id),
            domainId: selectedDomain ?? undefined,
          },
        },
      });

      if (res.errors?.length || res.data?.createGoal?.success === false) {
        formikHelpers.setStatus({
          errorMessage: "Oops! Something went wrong. Please try again.",
        });
      } else {
        afterUpdateSuccess?.();
      }
    } catch {
      formikHelpers.setStatus({
        errorMessage: "Oops! Something went wrong. Please try again.",
      });
    } finally {
      setSubmissionPending(false);
    }
  };

  const handleClose = () => {
    formikRef.current?.resetForm();
    setSelectedTemplateId(noTemplateOption.value);
    setSelectedDomain(null);
    setSelectedData([]);
    onRequestClose?.();
  };

  const isTemplateBased = selectedTemplateId !== noTemplateOption.value;
  return (
    <Modal opened={isOpen} onClose={handleClose} width={"1200px"}>
      <ModalHeader withSubHeader>Create New Goal</ModalHeader>

      {/* template select */}
      <ModalSubHeader>
        <Flex justify="space-between" align="center">
          <Box>
            <Select
              label="Template"
              disabled={templatesLoading}
              defaultValue={noTemplateOption.value}
              data={templateOptions}
              onChange={(selected) => {
                if (selected) {
                  setSelectedTemplateId(selected);
                  const template = templatesById[selected];
                  if (template?.domainId) {
                    setSelectedDomain(template.domainId);
                  } else {
                    setSelectedDomain(null);
                  }
                }
              }}
            />
          </Box>
          <Group spacing="md">
            <DatePickerInput
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              dropdownType="modal"
              placeholder="Select Date"
              clearable
              defaultValue={new Date()}
              label="Assigned Date"
            />
            <DatePickerInput
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              dropdownType="modal"
              placeholder="Select Date"
              clearable
              onChange={setSelectedDueDate}
              label="Due Date"
            />
          </Group>
        </Flex>
      </ModalSubHeader>

      {/* goal form */}
      <ModalBody>
        <Grid>
          <Grid.Col span={6}>
            <GoalTemplateForm
              selectedTemplate={selectedTemplate}
              formContext={TemplateFormContext.CreateGoal}
              onSubmit={handleSubmit}
              innerRef={formikRef}
              onValidStateChange={setCanSubmit}
              selectedDomain={selectedDomain}
              setSelectedDomain={setSelectedDomain}
              isDomainEditable={!isTemplateBased}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Text size="md" weight={600}>
              Interventions
            </Text>
            <SelectInterventionDropdown
              memberId={memberId}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
            />
          </Grid.Col>
        </Grid>

        {multiMemberActivity && (
          <Checkbox
            checked={createForAllMembersInActivity}
            onChange={(e) => setCreateForAllMembersInActivity(e.target.checked)}
            label={
              <>
                Create for all members of activity
                <QuestionTooltip
                  toolTipMessage={
                    "If checked, this will create a separate Goal for each member in the activity."
                  }
                />
              </>
            }
            mt={"1.5rem"}
          />
        )}
      </ModalBody>

      {/* form controls */}
      <ModalFooter>
        <Button
          color="red"
          variant="outline"
          onClick={onRequestClose}
          disabled={submissionPending}
        >
          Cancel
        </Button>

        <Button
          onClick={formikRef.current?.submitForm}
          disabled={!canSubmit}
          loading={submissionPending}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const noTemplateOption = {
  label: "None",
  value: BASE_CREATE_TEMPLATE_ID,
};

/**
 * wraps template options in Grouped SelectOption type, and appends "No Template" option group
 */
const wrapGroupedTemplateOptions = (templates: GoalTemplate[]) => {
  const templateOptions = templates.map((template) => ({
    label: template.name,
    value: template._id,
    group: "templates",
  }));

  return [noTemplateOption, ...templateOptions];
};
