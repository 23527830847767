import React, { useMemo } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import styled from "styled-components";

// Auth Routes
import {
  AuthRoutes,
  DefaultRouteBasedOnRole,
  RouteStyleConfig,
  SidebarSections,
} from "src/Router";

//  hooks
import { useAuthContext } from "src/hooks";
import { MASTHEAD_HEIGHT } from "./constants";
import { UserRole } from "src/graphql";
import { Alert, Stack } from "@mantine/core";
import dayjs from "dayjs";
import { IconBellRinging } from "@tabler/icons-react";
import { CustomerPortalButton } from "src/components/stripe-buttons/stripe-buttons";

const StyledContainer = styled.div<{
  omitBottomPadding: boolean;
  bgColor?: string;
}>`
  height: calc(100vh - ${MASTHEAD_HEIGHT}px);
  margin-top: ${MASTHEAD_HEIGHT}px;
  overflow-y: auto;
  flex-grow: 1;
  padding: ${({ omitBottomPadding }) =>
    omitBottomPadding ? "20px 20px 20px 20px" : "20px 20px 120px 20px"};
  background-color: ${({ bgColor }) => bgColor || "white"};
`;

const sidebarRoutes = SidebarSections.flat(1);

type ContentContainerProps = {
  omitBottomPadding: boolean;
};

export const ContentContainer = ({
  omitBottomPadding,
}: ContentContainerProps) => {
  const { currentUser, selectedOrganization } = useAuthContext();
  const location = useLocation();

  const defaultRoute = DefaultRouteBasedOnRole[currentUser.role];
  const shouldRedirectToAnalyticsPage = useMemo(() => {
    return (
      !location.pathname.includes("analytics") &&
      currentUser.role === UserRole.AnalyticsViewer
    );
  }, [currentUser.role, location.pathname]);

  if (shouldRedirectToAnalyticsPage) {
    return <Redirect to={defaultRoute} />;
  }

  const showSubscriptionExpiredAlert =
    !selectedOrganization.subscriptionIsActive ||
    (selectedOrganization.subscriptionDueDate &&
      dayjs(selectedOrganization.subscriptionDueDate).isBefore(new Date()));

  const bgColor =
    RouteStyleConfig[location.pathname as keyof typeof RouteStyleConfig]
      ?.bgColor;

  return (
    <StyledContainer omitBottomPadding={omitBottomPadding} bgColor={bgColor}>
      {showSubscriptionExpiredAlert && (
        <Alert
          title="Subscription Expired"
          color="red"
          variant="filled"
          mb="md"
          icon={<IconBellRinging />}
        >
          {currentUser.role === UserRole.Admin ||
          currentUser.role === UserRole.SuperAdmin ? (
            <Stack align="flex-start">
              Your subscription has expired. To continue enjoying uninterrupted
              access to our services, please renew your subscription at your
              earliest convenience.
              <CustomerPortalButton
                organization={selectedOrganization}
                variant="default"
                type="submit"
              >
                Renew Subscription
              </CustomerPortalButton>
            </Stack>
          ) : (
            <Stack align="flex-start">
              Your subscription has expired. Please contact your organization's
              admin to renew the subscription.
            </Stack>
          )}
        </Alert>
      )}

      <Switch>
        <Route exact path="/">
          <Redirect to={defaultRoute} />{" "}
        </Route>

        {Object.entries(AuthRoutes).map(([path, config]) => {
          const routeDetails = sidebarRoutes.find((page) =>
            page.children.some((child) => child.path === path)
          );

          if (
            routeDetails &&
            !routeDetails.allowedRoles.includes(currentUser.role)
          ) {
            return null;
          }

          // dropping this here until FlowUI is merged in with better route config
          return (
            <Route exact={config.exact} path={path} key={path}>
              <React.Suspense fallback={null}>
                {<config.component />}
              </React.Suspense>
            </Route>
          );
        })}
      </Switch>
    </StyledContainer>
  );
};
