import { gql } from "@apollo/client";
import { EventTemplateFields } from "./types";

export const CREATE_EVENT_TEMPLATE = gql`
  mutation CreateEventTemplate($input: EventTemplateInput!) {
    createEventTemplate(input: $input) {
      success
      message
      data {
        ${EventTemplateFields}
      }
    }
  }
`;

export const UPDATE_EVENT_TEMPLATE = gql`
  mutation UpdateEventTemplate($input: UpdateEventTemplateInput!) {
    updateEventTemplate(input: $input) {
      success
      message
      data {
        ${EventTemplateFields}
      }
    }
  }
`;

export const DELETE_EVENT_TEMPLATE = gql`
  mutation DeleteEventTemplate($id: ID!) {
    deleteEventTemplate(id: $id) {
      success
      message
      data {
        ${EventTemplateFields}
      }
    }
  }
`;
