import dayjs from "dayjs";
import log from "loglevel";
import { DATE_TIME_FORMAT } from "src/constants/common";

export const getFormattedTime = (
  timestamp: string,
  dateOnlyForNotToday = false
) => {
  const mDate = dayjs(timestamp);
  const now = new Date();

  const isToday = mDate.isSame(now, "day");
  if (isToday) return mDate.format("h:mm A");

  const isThisYear = mDate.isSame(now, "year");
  if (isThisYear) return mDate.format("M/D h:mm a");

  return mDate.format("M/D/YYYY h:mm A");
};

/**
 * Don't lean on this, I have not validated the regex -- just pulling in as a helper util
 * for seeking out non-ISO dates in the dataset when Moment throws the big console warning
 *
 * regex from: https://stackoverflow.com/questions/52869695/check-if-a-date-string-is-in-iso-and-utc-format
 */
export const logIfNotISO = (dateString: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateString))
    log.log("Probably not an ISO date: ", dateString);

  const date = new Date(dateString);
  if (date.toISOString() !== dateString) {
    log.log("Probably not an ISO date string:");
  }
};

export const formatDateTime = (input: string) =>
  dayjs(input).format(DATE_TIME_FORMAT);

export const parseDate = (dateString: string) =>
  dayjs(dateString).format("M/D/YYYY h:MM A");

export const formatDuration = (seconds: number) =>
  dayjs.duration(seconds, "seconds").format("HH:mm:ss");
