import { Button, Group, Stack, Text, Tooltip } from "@mantine/core";
import { Save } from "react-feather";
import styled from "@emotion/styled";
import { SectionSubHeader } from "src/components";
import { CallRoutingNodeType, useQueryUserGroups } from "src/graphql";
import { DragType, Draggable } from "src/components/drag-and-drop";

export const nodeTypeColors: Record<CallRoutingNodeType, string> = {
  [CallRoutingNodeType.Branching]: "red",
  [CallRoutingNodeType.NextAssignedUser]: "green",
  [CallRoutingNodeType.NextChw]: "yellow",
  [CallRoutingNodeType.UserGroup]: "cyan",
  [CallRoutingNodeType.Voicemail]: "blue",
  [CallRoutingNodeType.Say]: "violet",
};

export const nodeTypeLabels: Record<CallRoutingNodeType, string> = {
  [CallRoutingNodeType.Branching]: "Branching",
  [CallRoutingNodeType.NextAssignedUser]: "Next Assigned User",
  [CallRoutingNodeType.NextChw]: "Next CHW",
  [CallRoutingNodeType.UserGroup]: "User Group",
  [CallRoutingNodeType.Voicemail]: "Voicemail",
  [CallRoutingNodeType.Say]: "Say",
};

export const StyledToolbarNode = styled.div<{
  nodeType: CallRoutingNodeType;
  disabled: boolean;
}>`
  background-color: ${({ nodeType, disabled, theme }) =>
    disabled
      ? theme.colors.gray[5]
      : theme.colors[nodeTypeColors[nodeType]][5]};
  border-radius: 5px;
  padding: 3px 10px;
  color: white;
`;

type CallRoutingEditorToolbarProps = {
  canSubmit: boolean;
  isSubmitting: boolean;
  onSubmit: () => void;
};

export const CallRoutingEditorToolbar = ({
  canSubmit,
  isSubmitting,
  onSubmit,
}: CallRoutingEditorToolbarProps) => {
  const { data: userGroupsResponse, loading: userGroupsLoading } =
    useQueryUserGroups();

  const userGroupNodesDisabled =
    userGroupsLoading || userGroupsResponse?.userGroups?.data?.length === 0;
  return (
    <SectionSubHeader noGap>
      <Stack>
        <Group spacing="xs" align="center" h="100%">
          {Object.values(CallRoutingNodeType).map((nodeType) => {
            const nodeTypeDisabled =
              userGroupNodesDisabled && nodeType === "UserGroup";

            return (
              <Draggable
                type={DragType.CallRoutingNode}
                dragData={{ nodeType }}
                disabled={nodeTypeDisabled}
                key={nodeType}
              >
                <Tooltip
                  disabled={!nodeTypeDisabled}
                  label="Disabled (No user groups have been created)"
                >
                  <StyledToolbarNode
                    nodeType={nodeType}
                    disabled={nodeTypeDisabled}
                  >
                    <Text size="sm" weight={"bold"}>
                      {nodeTypeLabels[nodeType]}
                    </Text>
                  </StyledToolbarNode>
                </Tooltip>
              </Draggable>
            );
          })}

          <Button
            ml="auto"
            onClick={onSubmit}
            disabled={!canSubmit}
            loading={isSubmitting}
            leftIcon={<Save size={16} />}
          >
            Save Changes
          </Button>
        </Group>
      </Stack>
    </SectionSubHeader>
  );
};
