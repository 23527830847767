import { Tabs, Title } from "@mantine/core";
import React, { useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Integration, UserRole } from "src/graphql";
import { useAuthContext } from "src/hooks";
import { AuthContextType } from "src/providers";

export const BASE_CREATE_TEMPLATE_ID = "create";

export enum TemplateTabs {
  Activities = "activities",
  Flows = "flows",
  Goals = "goals",
  Emails = "emails",
  CarePathways = "carepathways",
  EHRNotes = "ehr-notes",
  Events = "events",
}

interface TemplateTabConfiguration {
  label: string;
  templateTab: TemplateTabs;
  component: React.LazyExoticComponent<React.ComponentType<unknown>>;
  allowedRoles: UserRole[];
  omit?: (ctx: AuthContextType) => boolean;
}

const tabs: TemplateTabConfiguration[] = [
  {
    label: "Activities",
    templateTab: TemplateTabs.Activities,
    component: React.lazy(
      () => import("../templates-activity/TemplatesListView")
    ),
    allowedRoles: [
      UserRole.SuperAdmin,
      UserRole.Admin,
      UserRole.Lead,
      UserRole.Chw,
    ],
  },
  {
    label: "Flows",
    templateTab: TemplateTabs.Flows,
    component: React.lazy(
      () => import("../templates-flow/FlowTemplatesListView")
    ),
    allowedRoles: [
      UserRole.SuperAdmin,
      UserRole.Admin,
      UserRole.Lead,
      UserRole.Chw,
    ],
  },
  {
    label: "Goals",
    templateTab: TemplateTabs.Goals,
    component: React.lazy(() => import("../templates-goal")),
    allowedRoles: [
      UserRole.SuperAdmin,
      UserRole.Admin,
      UserRole.Lead,
      UserRole.Chw,
    ],
  },
  {
    label: "Emails",
    templateTab: TemplateTabs.Emails,
    component: React.lazy(
      () => import("../templates-email/EmailTemplateListView")
    ),
    allowedRoles: [
      UserRole.SuperAdmin,
      UserRole.Admin,
      UserRole.Lead,
      UserRole.Chw,
    ],
  },
  {
    label: "Care Pathways",
    templateTab: TemplateTabs.CarePathways,
    component: React.lazy(
      () => import("../templates-carepathway/CarePathWayListView")
    ),
    allowedRoles: [
      UserRole.SuperAdmin,
      UserRole.Admin,
      UserRole.Lead,
      UserRole.Chw,
    ],
  },
  {
    label: "EHR Notes",
    templateTab: TemplateTabs.EHRNotes,
    component: React.lazy(
      () => import("../templates-ehr-notes/EHRNoteTemplatesListView")
    ),
    allowedRoles: [
      UserRole.SuperAdmin,
      UserRole.Admin,
      UserRole.Lead,
      UserRole.Chw,
    ],
    omit: (ctx) =>
      !ctx.selectedOrganization.activatedIntegrations?.includes(
        Integration.RedoxNoteSending
      ),
  },
  {
    label: "Events",
    templateTab: TemplateTabs.Events,
    component: React.lazy(
      () => import("../templates-event/EventTemplateListView")
    ),
    allowedRoles: [
      UserRole.SuperAdmin,
      UserRole.Admin,
      UserRole.Lead,
      UserRole.Chw,
    ],
  },
];

const Templates = () => {
  const authContext = useAuthContext();
  const history = useHistory();
  const { templateTab } = useParams<{ templateTab: string }>();

  const tabsToDisplay = useMemo(() => {
    return tabs.filter((tab) => {
      const tabToBeOmitted = !tab.omit ? false : tab.omit(authContext);
      const userHasPermissionToViewTab = tab.allowedRoles.includes(
        authContext.currentUser.role
      );
      return userHasPermissionToViewTab && !tabToBeOmitted;
    });
  }, [authContext]);

  return (
    <div>
      <Title style={{ marginBottom: "8px" }}>Templates</Title>
      <Tabs
        value={templateTab}
        onTabChange={(t) => {
          history.push(`/templates/${t as TemplateTabs}`);
        }}
      >
        <Tabs.List style={{ marginBottom: "16px" }}>
          {tabsToDisplay.map((tab) => (
            <Tabs.Tab key={tab.templateTab} value={tab.templateTab}>
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabs.map((tab) => (
          <Tabs.Panel key={tab.templateTab} value={tab.templateTab}>
            <React.Suspense fallback={null}>{<tab.component />}</React.Suspense>
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};

export default Templates;
