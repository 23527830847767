import { EnrollmentStatus, Sex } from "src/graphql";
import { getEnumStringValues, ySelectOptionSchema } from "src/utils";
import * as Yup from "yup";

export const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const MembersSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(2, "Name must be longer than 2 characters")
    .max(50, "Name must be shorter than 50 characters")
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .min(2, "Name must be longer than 2 characters")
    .max(50, "Name must be shorter than 50 characters")
    .required("Required"),
  nickName: Yup.string()
    .trim()
    .min(2, "Name must be longer than 2 characters")
    .max(50, "Name must be shorter than 50 characters")
    .nullable(true),
  medicareStatus: ySelectOptionSchema(
    Yup.string().oneOf(getEnumStringValues(EnrollmentStatus))
  ).default(undefined),
  medicaidStatus: ySelectOptionSchema(
    Yup.string().oneOf(getEnumStringValues(EnrollmentStatus))
  ).default(undefined),
  dob: Yup.date().nullable(true),
  socialSecurityNumber: Yup.string()
    .min(9, "SSN must be at least 9 characters")
    .nullable(true),
  sex: ySelectOptionSchema(
    Yup.string().oneOf(getEnumStringValues(Sex))
  ).default(undefined),
  primaryCIN: Yup.string().nullable(true),
  primaryInsuranceCompany: ySelectOptionSchema(Yup.string().required())
    .strict(true)
    .nullable(true),
  secondaryCIN: Yup.string().nullable(true),
  secondaryInsuranceCompany: ySelectOptionSchema(Yup.string().required())
    .strict(true)
    .nullable(true),
  contactInfo: Yup.object().shape({
    email: Yup.string().trim().email("Invalid email").nullable(true),
    organization: Yup.string().trim().required("Required"),
    address: Yup.string().trim().nullable(true),
    address2: Yup.string().trim().nullable(true),
    country: Yup.mixed(),
    phone: Yup.string()
      .matches(phoneRegex, "Invalid Phone number")
      .required("Required"),
    zip: Yup.string()
      .trim()
      .max(30, "Max length of character should be 30")
      .nullable(true),
  }),
  ehrID: Yup.string().nullable(true),
  ehrIDType: ySelectOptionSchema(Yup.string()).default(undefined),
});
