export const PhoneCallFields = `
    _id
    initiatingCallSid
    conferenceFriendlyName
    to
    from
    direction
    organizationId
    members{
        memberId
        firstName
        lastName
        nickName
    }
    duration
    recordingSid
    voicemailRecordingSid
    disposition
    userId
    userName
    voicemail
    createdAt
    updatedAt
`;

export const ClaimSuccessFields = `
    didClaim
`;
