import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
} from "react";
import { getAuth, User as FirebaseUser } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  AuthFlowContainer,
  AuthFlowState,
} from "src/components/auth/AuthFlowContainer";
import { useHistory, useLocation } from "react-router-dom";
import toast from "src/libs/toast";
import {
  Organization,
  User,
  useQueryUserState,
  UserRole,
  MfaPolicy,
  OrganizationStatus,
} from "src/graphql";
import { SelectOption } from "src/types";
import { getUserPerms } from "src/utils/user";
import { useURLQueryParams } from "src/hooks";

const authFlowRoutes = [
  "/login",
  "/reset",
  "/signup",
  "/forgot-password",
  "/onboarding-survey",
  "/referral-status",
  "/plans",
];

export type AuthContextType = {
  currentUser: User;
  firebaseUser: FirebaseUser;
  selectedOrganizationId: string;
  selectedOrganization: Organization;
  organizations: Organization[];
  organizationOptions: SelectOption<string>[];
  groupOptions: SelectOption<string>[];
  userPerms: {
    isSuperAdmin: boolean;
    isAdmin: boolean;
    isLead: boolean;
    isChw: boolean;
    isRP: boolean;
    atLeastOrgAdmin: boolean;
    atLeastOrgLead: boolean;
  };
  memberPageReadOnly: boolean;
  signOut: () => void;
  setSelectedOrganizationId: (nextId: string) => boolean;
};

// helper for lacking types from Firebase UI -- this is the shape that exists on a user
// object post-reloading of session if they're authenticated with a multifactor session
type MfaAugmentedFirebaseUser = FirebaseUser & {
  reloadUserInfo?: {
    mfaInfo?: Array<unknown>;
  };
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

type AuthProviderProps = {
  children: React.ReactNode;
};

export enum UserPerm {
  IsSuperAdmin = "isSuperAdmin",
  IsAdmin = "isAdmin",
  IsLead = "isLead",
  IsChw = "isChw",
  IsRP = "isRP",
  AtLeastOrgAdmin = "atLeastOrgAdmin",
  AtLeastOrgLead = "atLeastOrgLead",
}

export type UserPermsType = {
  [key in UserPerm]: boolean;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = getAuth();
  const history = useHistory();
  const location = useLocation();
  const urlQueryParams = useURLQueryParams();
  const [firebaseUser, authStateLoading] = useAuthState(auth);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
  const [memberPageReadOnly, setMemberPageReadOnly] = useState(false);
  const [authFlowState, setAuthFlowState] = useState<AuthFlowState>(
    AuthFlowState.Loading
  );

  // fetch the PearSuite user and organizations info for user; skips when uid unset
  const { data: pearUserStateResponse } = useQueryUserState(
    firebaseUser?.uid ?? ""
  );

  const rootPath = useMemo(() => {
    if (location.pathname) {
      return location.pathname.split("/").slice(0, 2).join("/");
    }
    return "/";
  }, [location.pathname]);
  // destructure & process PearSuite user data response for convenience
  const [pearUser, organizations, userPerms] = useMemo(
    () => [
      pearUserStateResponse?.user.data,
      pearUserStateResponse?.organizationsForUser?.data ?? [],
      getUserPerms(pearUserStateResponse?.user.data),
    ],
    [pearUserStateResponse]
  );

  useEffect(() => setMemberPageReadOnly(userPerms.isRP), [userPerms]);

  const activeOrgainzations = useMemo(
    () => organizations.filter((o) => o.status === OrganizationStatus.Active),
    [organizations]
  );

  const organizationOptions = useMemo(
    () =>
      activeOrgainzations
        .map((org) => ({
          label: org.title,
          value: org._id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [activeOrgainzations]
  );

  /**
   * Helper callbacks for use in other components
   */
  const handleUserAuthenticated = useCallback(() => {
    setAuthFlowState(AuthFlowState.Authenticated);
    if (authFlowRoutes.includes(rootPath)) {
      let redirect = urlQueryParams.get("redirect");
      if (redirect === "/settings") {
        redirect = "/settings/profile";
      }
      history.push(redirect ?? "/");
    }
  }, [setAuthFlowState, history, rootPath, urlQueryParams]);

  const handleSignOut = () => {
    auth.signOut();
  };

  const handleSelectOrg = useCallback(
    (nextId: string) => {
      const org = activeOrgainzations.find((org) => org._id === nextId);
      if (!org) {
        // should never be the case, options built from orgs array
        toast.error("Could not select requested organization");
        return false;
      }
      localStorage.setItem("lastSelectedOrgName", org.title);
      setSelectedOrganizationId(nextId);
      return true;
    },
    [activeOrgainzations]
  );

  const selectedOrganization = useMemo(
    () => activeOrgainzations.find((org) => org._id === selectedOrganizationId),
    [selectedOrganizationId, activeOrgainzations]
  );

  const groupOptions = useMemo(
    () =>
      selectedOrganization?.groups
        ?.map((group) => ({
          label: group.title,
          value: group._id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [selectedOrganization]
  );

  /**
   * Auth flow state MGMT
   */

  // when initial auth state is loaded
  useEffect(() => {
    if (authStateLoading) return;

    // no active session, set state and ensure login route if not already auth route
    if (
      !firebaseUser &&
      ![
        AuthFlowState.TwoFactorEnrollmentRequired,
        AuthFlowState.TwoFactorLoginRequired,
      ].includes(authFlowState)
    ) {
      setAuthFlowState(AuthFlowState.LoggedOut);
      if (!authFlowRoutes.includes(rootPath)) {
        history.push(`/login?redirect=${rootPath}`);
      }
    }

    // previous session still valid
    if (
      firebaseUser &&
      [AuthFlowState.LoggedOut, AuthFlowState.Loading].includes(authFlowState)
    ) {
      handleUserAuthenticated();
    }
  }, [
    authStateLoading,
    authFlowState,
    firebaseUser,
    history,
    rootPath,
    handleUserAuthenticated,
    memberPageReadOnly,
  ]);

  // when session is valid, and PearUser and organizations are loaded
  useEffect(() => {
    if (firebaseUser && pearUser && activeOrgainzations) {
      // check if two-factor is required; either user is superadmin user,
      // or organization has a two-factor policy set.
      if (
        pearUser.role === UserRole.SuperAdmin ||
        activeOrgainzations.some((org) => org.mfaPolicy !== MfaPolicy.None)
      ) {
        // if this firebaseUser user already has mfaInfo properties set,
        // then this session was already initiated with 2fa. Otherwise,
        // they need to enroll.
        if (
          !(firebaseUser as MfaAugmentedFirebaseUser).reloadUserInfo?.mfaInfo
            ?.length
        ) {
          setAuthFlowState(AuthFlowState.TwoFactorEnrollmentRequired);
          if (!authFlowRoutes.includes(rootPath)) {
            history.push(`/login?redirect=${rootPath}`);
          }
        }
      }

      // if user is not superadmin, no orgs available, set NoOrgs state
      if (
        pearUser.role !== UserRole.SuperAdmin &&
        !activeOrgainzations.length
      ) {
        setAuthFlowState(AuthFlowState.NoOrgs);
        return;
      }

      // otherwise, select initial org if none selected
      if (!selectedOrganizationId) {
        const lastSelectedName = localStorage.getItem("lastSelectedOrgName");
        const lastSelectedOrgId = activeOrgainzations.find(
          (org) => org.title === lastSelectedName
        )?._id;
        handleSelectOrg(lastSelectedOrgId ?? activeOrgainzations[0]._id);
        return;
      }
    }
  }, [
    pearUser,
    firebaseUser,
    selectedOrganizationId,
    activeOrgainzations,
    handleSelectOrg,
    history,
    rootPath,
  ]);

  // when this is true, authentication is complete and required app state is loaded
  const applicationReady =
    authFlowState === AuthFlowState.Authenticated &&
    !!firebaseUser &&
    !!pearUser &&
    !!selectedOrganizationId &&
    !!selectedOrganization;

  return applicationReady ? (
    <AuthContext.Provider
      value={{
        currentUser: pearUser,
        userPerms,
        firebaseUser,
        selectedOrganizationId,
        selectedOrganization,
        organizations,
        organizationOptions,
        groupOptions,
        signOut: handleSignOut,
        setSelectedOrganizationId: handleSelectOrg,
        memberPageReadOnly,
      }}
    >
      {children}
    </AuthContext.Provider>
  ) : (
    <AuthFlowContainer
      onUserAuthenticated={handleUserAuthenticated}
      onSignOut={handleSignOut}
      authFlowState={authFlowState}
      firebaseUser={firebaseUser}
      setAuthFlowState={setAuthFlowState}
    />
  );
};
