import { Stack, Box, Text, Select } from "@mantine/core";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  EditableItem,
  FormErrorNotice,
  LoaderComponent,
  makeEditableItemsArray,
  StyledErrorMessage,
} from "src/components";
import { FormikInput } from "src/components/input";
import { FormikTextEditor } from "src/components/input/FormikTextEditor/FormikTextEditor";
import { GoalTemplate, usePaginatedQueryDomains } from "src/graphql";
import { BASE_CREATE_TEMPLATE_ID } from "src/pages/templates";
import { arrayToKeyedObj } from "src/utils";
import { ActionItemList } from "./ActionItemList";
import { GoalTemplateFormSchema } from "./schema";
import { useAuthContext } from "src/hooks";

/**
 * A Form component used for creating/editing GoalTemplates
 * - expects save/cancel/etc controls to be rendered by parent component
 * - Formik API methods are exposed to parent via `innerRef` prop
 */

export const makeBaseGoalTemplate = (organizationId: string): GoalTemplate => ({
  _id: BASE_CREATE_TEMPLATE_ID,
  name: "",
  actionItemDescriptions: [],
  organizationId,
  description: "",
  lockingReferences: [],
  domainId: undefined,
});

export enum TemplateFormContext {
  CreateGoal = 0,
  CreateTemplate,
}

type GoalTemplateFormProps = {
  selectedTemplate?: GoalTemplate;
  onSubmit: (
    template: GoalTemplate,
    helpers: FormikHelpers<GoalTemplateFormValues>
  ) => void;
  formContext: TemplateFormContext;
  /**
   * Disables form inputs and updating
   * @default false
   */
  readOnly?: boolean;
  /**
   * Exposes Formik's instance API, e.g. functions like ref.current.submitForm()
   */
  innerRef: React.RefObject<FormikProps<GoalTemplateFormValues>>;
  /**
   * Optional callback to subscribe to Formik's "dirty" state
   */
  onDirtyStateChange?: (dirty: boolean) => void;
  /**
   * Optional callback to subscribe to Formik's "isValid" state
   */
  onValidStateChange?: (dirty: boolean) => void;
  selectedDomain?: string | null;
  setSelectedDomain?: (domainId: string | null) => void;
  isDomainEditable?: boolean;
};

export const GoalTemplateForm = ({
  selectedTemplate,
  innerRef,
  formContext,
  readOnly = false,
  onSubmit,
  onDirtyStateChange,
  onValidStateChange,
  selectedDomain: propSelectedDomain,
  setSelectedDomain: propSetSelectedDomain,
  isDomainEditable = true,
}: GoalTemplateFormProps) => {
  const dirtyStateRef = useRef(false);
  const validStateRef = useRef(false);
  const { selectedOrganizationId: organizationId } = useAuthContext();
  const [selectedDomain, setSelectedDomain] = useState<string | null>(
    propSelectedDomain ?? selectedTemplate?.domainId ?? null
  );

  // reset form state whenever template changes
  useEffect(() => {
    dirtyStateRef.current = false;
    validStateRef.current = false;
    onDirtyStateChange?.(false);
    onValidStateChange?.(false);
    innerRef?.current?.resetForm();
  }, [
    selectedTemplate,
    innerRef,
    dirtyStateRef,
    validStateRef,
    onDirtyStateChange,
    onValidStateChange,
  ]);

  const { data: domainsResponse } = usePaginatedQueryDomains({
    organizationId,
  });

  const initialValues = useMemo(
    () => (selectedTemplate ? wrapFormValues(selectedTemplate) : null),
    [selectedTemplate]
  );

  useEffect(() => {
    if (selectedTemplate?.domainId) {
      setSelectedDomain(selectedTemplate.domainId);
    }
  }, [selectedTemplate]);

  if (initialValues === null)
    return (
      <LoaderComponent
        // A little magic numbery here, sorry :<
        // This form is currently rendered in modals which drop the top padding a little
        // to account for extra whitespace added by their first elements being <StyledLabel />s
        //
        // This extra margin-top centers the loader in those modal bodies
        style={{ marginTop: 5 }}
      />
    );

  const currentSetSelectedDomain = propSetSelectedDomain ?? setSelectedDomain;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={GoalTemplateFormSchema}
      onSubmit={(values, helpers) => {
        !readOnly && onSubmit(unwrapFormValues(values), helpers);
      }}
      validateOnMount
      innerRef={innerRef}
      enableReinitialize
    >
      {({ values, status, dirty, isValid, handleSubmit, setFieldValue }) => {
        if (onDirtyStateChange && dirty !== dirtyStateRef.current) {
          dirtyStateRef.current = dirty;
          requestAnimationFrame(() => onDirtyStateChange(dirty));
        }
        if (onValidStateChange && isValid !== validStateRef.current) {
          validStateRef.current = isValid;
          requestAnimationFrame(() => onValidStateChange(isValid));
        }

        return (
          <Form onSubmit={handleSubmit} noValidate>
            <Stack spacing="md">
              <Box>
                <FormikInput
                  required
                  label="Name"
                  type="text"
                  name="name"
                  disabled={
                    readOnly ||
                    (formContext === TemplateFormContext.CreateGoal &&
                      selectedTemplate?._id !== BASE_CREATE_TEMPLATE_ID)
                  }
                  placeholder="Goal Name"
                />
              </Box>
              <div>
                <Select
                  data={(domainsResponse?.data ?? []).map((domain) => ({
                    value: domain._id,
                    label: domain.title,
                  }))}
                  value={selectedDomain}
                  clearable
                  onChange={(value) => {
                    currentSetSelectedDomain(value);
                    setSelectedDomain(value);
                    setFieldValue("domainId", value);
                  }}
                  label="Domain"
                  placeholder="Select a domain..."
                  disabled={!isDomainEditable}
                />
              </div>

              <Box>
                <FormikTextEditor
                  name="description"
                  label="Description"
                  disabled={readOnly}
                  hidePlaceholderButton={
                    readOnly || formContext === TemplateFormContext.CreateGoal
                  }
                  onChangeOverride={(value: string) =>
                    setFieldValue("description", value)
                  }
                  initialValue={initialValues.description}
                  style={{ maxHeight: 100 }}
                />
              </Box>

              <div>
                <Box>
                  <Text size="sm" weight={500}>
                    Action Items
                  </Text>
                  <ActionItemList
                    itemsById={values.actionItemDescriptions}
                    onChange={(nextItems) =>
                      setFieldValue("actionItemDescriptions", nextItems)
                    }
                    readOnly={readOnly}
                  />
                  <StyledErrorMessage name="actionItemDescriptions" />
                </Box>
              </div>

              {status?.errorMessage && (
                <Box mt="md">
                  <FormErrorNotice {...status} />
                </Box>
              )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

// type for wrapped form types representing GoalTemplate
export type GoalTemplateFormValues = Omit<
  GoalTemplate,
  "actionItemDescriptions"
> & {
  actionItemDescriptions: Record<string, EditableItem>;
};

/**
 * Wraps GoalTemplate values into wrapper types used by form inputs
 */
const wrapFormValues = (template: GoalTemplate) => ({
  ...template,
  actionItemDescriptions: arrayToKeyedObj(
    makeEditableItemsArray(template.actionItemDescriptions ?? []),
    "id"
  ),
  domainId: template.domainId ?? undefined,
});

/**
 * Unwraps form values from e.g. SelectOption<T>, EditableItem types back to GoalTemplate type
 */
const unwrapFormValues = (values: GoalTemplateFormValues): GoalTemplate => ({
  ...values,
  actionItemDescriptions: Object.values(values.actionItemDescriptions).map(
    (item) => item.content
  ),
  domainId: values.domainId ?? undefined,
});
