import dayjs from "dayjs";
import { useMemo } from "react";
import {
  GetFlowBuilderDataResponse,
  FlowTemplate,
  ActionType,
  Question,
  GoalTemplate,
  ActivityTemplate,
  DataIdWithAnswerType,
  DataIdWithAnswerTypeResponse,
  useQueryFlowBuilderData,
  FlowType,
  FlowTemplateFamily,
  ExternalResource,
  CarePathwayTemplate,
  ConsentTemplate,
  Group,
} from "src/graphql";
import { useQueryDataIdsWithAnswerTypesByOrganizationId } from "src/graphql/DataPoint";
import { useAuthContext } from "src/hooks";
import { BASE_CREATE_TEMPLATE_ID } from "src/pages/templates";
import { arrayToKeyedObj } from "src/utils";
import { makeDefaultStagePositions } from "./template-editor";
import { BUILDER_HEADER_HEIGHT } from "./template-editor/FlowTemplateBuilderHeader";

export type FlowBuilderData = {
  flowTemplatesById: Record<string, FlowTemplate>;
  flowTemplateFamiliesById: Record<string, FlowTemplateFamily>;
  questionsById: Record<string, Question>;
  dataIdInformaionById: Record<string, DataIdWithAnswerType>;
  actionOptionsByTypeById: {
    [ActionType.CreateGoalData]: Record<string, GoalTemplate>;
    [ActionType.UpdateGoalData]: Record<string, GoalTemplate>;
    [ActionType.ScheduleActivityData]: Record<string, ActivityTemplate>;
    [ActionType.UpdateMemberData]: Record<string, DataIdWithAnswerType>;
    [ActionType.CreateReferral]: Record<string, ExternalResource>;
    [ActionType.StartCarePathway]: Record<string, CarePathwayTemplate>;
    [ActionType.UpdateCarePathwayStatus]: Record<string, CarePathwayTemplate>;
    [ActionType.CreateConsent]: Record<string, ConsentTemplate>;
    [ActionType.AddMemberToGroup]: Record<string, Group>;
    [ActionType.RemoveMemberFromGroup]: Record<string, Group>;
  };
};

export const useFlowBuilderData = () => {
  const { selectedOrganizationId, selectedOrganization } = useAuthContext();
  const { data: builderDataResponse, loading: builderDataLoading } =
    useQueryFlowBuilderData(selectedOrganizationId);

  const { data: dataIdsByOrganizationResponse, loading: dataIdDataLoading } =
    useQueryDataIdsWithAnswerTypesByOrganizationId(selectedOrganizationId);

  const parsedData = useParsedFlowBuilderData(
    builderDataResponse,
    dataIdsByOrganizationResponse?.dataIdsWithAnswerTypesByOrganizationId,
    selectedOrganization.groups ?? []
  );

  return {
    isLoading: builderDataLoading || dataIdDataLoading,
    parsedData,
  };
};

export const useParsedFlowBuilderData = (
  builderDataResponse?: GetFlowBuilderDataResponse,
  dataIdResponse?: DataIdWithAnswerTypeResponse,
  groups?: Group[]
): FlowBuilderData | null => {
  return useMemo(() => {
    if (!builderDataResponse || !dataIdResponse) return null;

    const flowTemplateFamilies =
      builderDataResponse.flowTemplateFamilies.data ?? [];
    const goalTemplates = builderDataResponse.goalTemplates.data ?? [];
    const carePathwayTemplates =
      builderDataResponse.carePathwayTemplatesByOrganizationId.data ?? [];
    const activityTemplates = builderDataResponse.activityTemplates.data ?? [];
    const questions = builderDataResponse.getQuestions.data ?? [];
    const dataIds = dataIdResponse?.data ?? [];
    const consentTemplates =
      builderDataResponse.consentTemplatesByOrganizationId.data ?? [];
    const externalResources = builderDataResponse.externalResources.data ?? [];

    const flowTemplatesById = flowTemplateFamilies.reduce((byId, family) => {
      byId[family.currentVersion._id] = family.currentVersion;
      family.priorVersions.forEach(
        (template) => (byId[template._id] = template)
      );
      return byId;
    }, {} as Record<string, FlowTemplate>);

    const flowTemplateFamiliesById = flowTemplateFamilies.reduce(
      (byId, family) => ({ ...byId, [family.currentVersion.familyId]: family }),
      {} as Record<string, FlowTemplateFamily>
    );

    const questionsById = arrayToKeyedObj(questions, "_id");
    const goalTemplatesById = arrayToKeyedObj(goalTemplates, "_id");
    const carePathwayTemplatesById = arrayToKeyedObj(
      carePathwayTemplates,
      "_id"
    );
    const activityTemplatesById = arrayToKeyedObj(activityTemplates, "_id");
    const dataIdInformaionById: Record<string, DataIdWithAnswerType> =
      arrayToKeyedObj(dataIds, "dataId");
    const externalResourcesById = arrayToKeyedObj(externalResources, "_id");
    const consentTemplatesById = arrayToKeyedObj(consentTemplates, "_id");
    const groupsById = arrayToKeyedObj(groups ?? [], "_id");

    const actionOptionsByTypeById = {
      [ActionType.CreateGoalData]: goalTemplatesById,
      [ActionType.ScheduleActivityData]: activityTemplatesById,
      [ActionType.UpdateGoalData]: goalTemplatesById,
      [ActionType.UpdateMemberData]: dataIdInformaionById,
      [ActionType.CreateReferral]: externalResourcesById,
      [ActionType.StartCarePathway]: carePathwayTemplatesById,
      [ActionType.UpdateCarePathwayStatus]: carePathwayTemplatesById,
      [ActionType.CreateConsent]: consentTemplatesById,
      [ActionType.AddMemberToGroup]: groupsById,
      [ActionType.RemoveMemberFromGroup]: groupsById,
    };

    return {
      flowTemplatesById,
      flowTemplateFamiliesById,
      questionsById,
      dataIdInformaionById,
      actionOptionsByTypeById,
    };
  }, [builderDataResponse, dataIdResponse, groups]);
};

const defaultStartNodeX = 100;

export const createBaseFlowTemplate = (
  organizationId: string,
  type: FlowType
): FlowTemplate => ({
  _id: BASE_CREATE_TEMPLATE_ID,
  chapters: [],
  createdAt: dayjs().toISOString(),
  updatedAt: dayjs().toISOString(),
  organizationId: organizationId,
  startNodePosition: {
    x: defaultStartNodeX,
    y: window.innerHeight / 2 - BUILDER_HEADER_HEIGHT,
  },
  stagePositions: makeDefaultStagePositions(defaultStartNodeX),
  entryStepId: "",
  steps: [],
  title: "",
  lockingReferences: [],
  lockedByUse: false,
  flowType: type,
  familyId: "THIS_IS_A_DUMMY_ID_IGNORE_THIS", // set by backend on creation
  version: 1,
  isActive: true,
});
