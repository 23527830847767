import { gql } from "@apollo/client";
import { EmailTemplateFieldsMeta, EmilTemplateFields } from "./types";

export const GET_EMAIL_TEMPLATES = gql`
    query GetEmailTemplates($organizationId: ID!) {
        emailTemplates(organizationId: $organizationId) {
            success
            message
            data {
              ${EmilTemplateFields}
            }
        }
    }
`;

export const GET_EMAIL_TEMPLATES_META = gql`
  query GetEmailTemplatesMeta($organizationId: ID!) {
    emailTemplates(organizationId: $organizationId) {
      success
      message
      data {
       ${EmailTemplateFieldsMeta}
      }
    }
  }
`;

export const GET_EMAIL_TEMPLATE = gql`
    query GetEmailTemplate($input: EmailTemplateIdAndOrgInput!) {
        emailTemplate(input: $input) {
            success
            message
            data {
              ${EmilTemplateFields}
            }
        }
    }
`;
