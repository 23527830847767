import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useEffect, useMemo, useRef, useState } from "react";
import { Eye, EyeOff, Plus } from "react-feather";
import toast from "src/libs/toast";
import { CreateNoteInput, GetNotesInput, Note } from "src/graphql";
import {
  useMutationCreateNote,
  useMutationUpdateNote,
  useQueryNotes,
} from "src/graphql/Note";
import { useAuthContext } from "../../hooks/useAuthContext";
import { css } from "@emotion/css";
import { getFormattedTime } from "src/utils";
import { QuestionTooltip } from "../question-tooltip";

type NotesHookOptions = {
  listOptions: Omit<GetNotesInput, "organizationId">;
  createOptions: Omit<
    CreateNoteInput,
    "content" | "authorId" | "organizationId"
  >;
};

const useNotes = ({ listOptions, createOptions }: NotesHookOptions) => {
  const { currentUser, selectedOrganizationId } = useAuthContext();

  const notesQuery = useQueryNotes({
    organizationId: selectedOrganizationId,
    ...listOptions,
  });

  const [mutationCreateNote] = useMutationCreateNote();
  const [mutationUpdateNote] = useMutationUpdateNote();

  const notes = useMemo(() => notesQuery.data?.notes.data ?? [], [notesQuery]);

  const redactNote = async (note: Note) => {
    try {
      const response = await mutationUpdateNote({
        variables: {
          input: {
            noteId: note._id,
            redacted: !note.redacted,
            organizationId: selectedOrganizationId,
          },
        },
      });

      if (!response.data?.updateNote.success)
        throw new Error(response.data?.updateNote.message);
    } catch (error) {
      toast.error(
        error instanceof Error && error.message
          ? error.message
          : "Something went wrong while udpating note"
      );
    }
  };

  const createNote = async (
    content: string,
    createForAllMembersInActivity?: boolean
  ) => {
    try {
      const response = await mutationCreateNote({
        variables: {
          input: {
            ...createOptions,
            authorId: currentUser._id,
            organizationId: selectedOrganizationId,
            content,
            createForAllMembersInActivity:
              createForAllMembersInActivity ?? false,
          },
        },
      });

      if (!response.data?.createNote.success)
        throw new Error(response.data?.createNote.message);
    } catch (error) {
      toast.error(
        error instanceof Error && error.message
          ? error.message
          : "Something went wrong while creating the note"
      );
    }
  };

  return { notes, redactNote, createNote };
};

const notesStyles = css`
  & .hover-controls {
    display: none;
  }

  &:hover .hover-controls {
    display: block;
  }
`;

type NotesProps = NotesHookOptions & {
  showEditor?: boolean;
  showEditorOnChange?: (state: boolean) => void;
  hideAddButton?: boolean;
  hideNoNotesPlaceholder?: boolean;
  multiMemberActivity?: boolean;
  readonly?: boolean;
};

export const Notes = (options: NotesProps) => {
  const { notes, createNote, redactNote } = useNotes(options);
  const [showEditor, setShowEditor] = useState(options.showEditor ?? false);

  // Controlled editor
  const handleEditorStateChange = (state: boolean) => {
    if (options.showEditorOnChange) options.showEditorOnChange(state);
    else setShowEditor(state);
  };

  useEffect(
    () => setShowEditor((currentState) => options.showEditor ?? currentState),
    [options.showEditor]
  );

  return (
    <Stack spacing="xs" h="100%">
      {notes.map((note) => {
        const header = [getFormattedTime(note.createdAt), note.authorName].join(
          " - "
        );

        return (
          <Stack spacing={0} key={note._id} className={notesStyles}>
            <Text size="xs" color="gray">
              {header}
            </Text>

            <Group align="flex-start" spacing="xs">
              <Text strikethrough={note.redacted}>{note.content}</Text>

              <Tooltip label="Redact" className="hover-controls">
                <ActionIcon
                  size="sm"
                  variant="transparent"
                  color={note.redacted ? "red" : "green"}
                  onClick={() => redactNote(note)}
                >
                  {note.redacted ? <EyeOff size={14} /> : <Eye size={14} />}
                </ActionIcon>
              </Tooltip>
            </Group>
          </Stack>
        );
      })}

      {notes.length === 0 && !options.hideNoNotesPlaceholder && (
        <Text color="gray">No notes to display</Text>
      )}

      {showEditor && (
        <NoteEditor
          key="editor"
          onSubmit={(content, createForAllMembersInActivity) =>
            createNote(content, createForAllMembersInActivity)
          }
          onCancel={() => handleEditorStateChange(false)}
          multiMemberActivity={options.multiMemberActivity}
        />
      )}

      {!showEditor && !options.hideAddButton && !options.readonly && (
        <Group key="add-button" position="center">
          <Button
            fullWidth
            variant="white"
            leftIcon={<Plus size="18" />}
            data-testid="add-note-button"
            onClick={() => handleEditorStateChange(true)}
          >
            Add Note
          </Button>
        </Group>
      )}
    </Stack>
  );
};

interface NoteEditorProps {
  onSubmit: (content: string, createForAllMembersInActivity?: boolean) => void;
  onCancel: () => void;
  multiMemberActivity?: boolean;
}

export const NoteEditor = ({
  onSubmit,
  onCancel,
  multiMemberActivity,
}: NoteEditorProps) => {
  const inputElementRef = useRef<HTMLTextAreaElement>(null);
  const [content, setContent] = useState("");
  const [createForAllMembersInActivity, setCreateForAllMembersInActivity] =
    useState(false);

  const handleCancel = () => {
    setContent("");
    onCancel();
  };

  const handleSubmit = () => {
    if (content.trim() === "") return;
    setContent("");
    onSubmit(content.trim(), createForAllMembersInActivity);
  };

  /**
   * Handle special keys
   * - enter:       submit
   * - shift+enter: newline
   * - esc:         cancel
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }

    if (event.key === "Escape") {
      event.stopPropagation();
      handleCancel();
    }
  };

  return (
    <div>
      <Textarea
        ref={inputElementRef}
        id="newItem"
        name="item"
        autoFocus
        required
        value={content}
        onChange={(e) => setContent(e.currentTarget.value)}
        onKeyDown={handleKeyDown}
        data-testid="note-editor"
      />

      <Group
        sx={{
          marginTop: 10,
          justifyContent: "space-between",
        }}
      >
        {multiMemberActivity && (
          <Checkbox
            checked={createForAllMembersInActivity}
            onChange={(e) => setCreateForAllMembersInActivity(e.target.checked)}
            label={
              <>
                Create for all
                <QuestionTooltip
                  toolTipMessage={
                    "If checked, this will create the note for all members in the activity."
                  }
                />
              </>
            }
          />
        )}
        <Group position="right" w={!multiMemberActivity ? "100%" : undefined}>
          <Button compact color="red" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            compact
            disabled={content.trim() === ""}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Group>
      </Group>
    </div>
  );
};
