import { gql } from "@apollo/client";
import { ActivityTemplateFields } from "./types";
import { questionFields } from "../Question/types";

export const GET_ACTIVITY_TEMPLATES = gql`
  query GetActivityTemplates($organizationId: ID!) {
    activityTemplates(organizationId: $organizationId) {
      success
      message
      data {
        ${ActivityTemplateFields}
      }
    }
  }
`;

export const GET_ACTIVITY_TEMPLATE = gql`
  query GetActivityTemplate($activityTemplateId: ID!) {
    activityTemplate(activityTemplateId: $activityTemplateId) {
      success
      message
      data {
        ${ActivityTemplateFields}
      }
    }
  }
`;

export const GET_ACTIVITY_TEMPLATE_QUESTIONS = gql`
  query GetActivityTemplateQuestions($organizationId: ID!, $activityTemplateId: ID!) {
    getActivityTemplateQuestions(organizationId: $organizationId, activityTemplateId: $activityTemplateId) {
      success
      message
      data {
        ${questionFields}
      }
    }
  }
`;
