import { Grid, Title, TextInput } from "@mantine/core";
import { useFormikContext, Field } from "formik";
import { MemberProfileSettings, Language, Ethnicity } from "src/graphql";
import type { MemberFormValues } from "../MemberFormFormik";
import { FormikInput, FormikMultiSelect, FormikSelect } from "src/components";
import { FormikDateInput } from "src/components/input/FormikDateInput";
import { SelectOption } from "src/types";

interface MemberProfileInfoProps {
  memberProfileSettings: MemberProfileSettings;
  raceSelectOptions: SelectOption<string>[];
  sexSelectOptions: SelectOption<string>[];
  genderSelectOptions: SelectOption<string>[];
  pronounsSelectOptions: SelectOption<string>[];
  sexualOrientationSelectOptions: SelectOption<string>[];
  pregnancyStatusSelectOptions: SelectOption<string>[];
  languageSelectOptions: SelectOption<Language>[];
  ethnicitySelectOptions: SelectOption<Ethnicity>[];
  shouldShowTitle: boolean;
}

export const MemberProfileInformationSection = ({
  memberProfileSettings,
  raceSelectOptions,
  sexSelectOptions,
  genderSelectOptions,
  pronounsSelectOptions,
  sexualOrientationSelectOptions,
  pregnancyStatusSelectOptions,
  languageSelectOptions,
  ethnicitySelectOptions,
  shouldShowTitle,
}: MemberProfileInfoProps) => {
  const { errors, touched } = useFormikContext<MemberFormValues>();
  return (
    <>
      {shouldShowTitle && (
        <Title order={2} style={{ marginBottom: 20 }}>
          Profile
        </Title>
      )}
      <Grid gutter="md">
        {!memberProfileSettings.memberPreferredNameHidden && (
          <Grid.Col md={3} sm={6}>
            {shouldShowTitle ? (
              <Field
                as={TextInput}
                label="Preferred Name"
                type="text"
                name="nickName"
                variant="filled"
                radius="md"
                error={touched.nickName && errors.nickName}
                placeholder="Enter Preferred Name"
              />
            ) : (
              <FormikInput
                type="text"
                name="nickName"
                label="Preferred Name"
                placeholder="Enter Preferred Name"
              />
            )}
          </Grid.Col>
        )}
        {!memberProfileSettings.memberDOBHidden && (
          <Grid.Col md={3} sm={6}>
            <FormikDateInput
              name="dob"
              label="Date of Birth"
              placeholder="YYYY-MM-DD"
            />
          </Grid.Col>
        )}
        {!memberProfileSettings.memberRaceHidden && (
          <Grid.Col md={3} sm={6}>
            <FormikMultiSelect
              name="race"
              label="Race(s)"
              options={raceSelectOptions}
              placeholder="Select one or more races"
            />
          </Grid.Col>
        )}
        {!memberProfileSettings.memberEthnicityHidden && (
          <Grid.Col md={3} sm={6}>
            <FormikSelect
              label="Ethnicity"
              name="ethnicity"
              options={ethnicitySelectOptions}
              placeholder="Enter Ethnicity"
            />
          </Grid.Col>
        )}
        {!memberProfileSettings.memberSexHidden && (
          <Grid.Col md={3} sm={6}>
            <FormikSelect name="sex" label="Sex" options={sexSelectOptions} />
          </Grid.Col>
        )}
        {!memberProfileSettings.memberGenderHidden && (
          <Grid.Col md={3} sm={6}>
            <FormikMultiSelect
              name="gender"
              label="Gender Identity(s)"
              options={genderSelectOptions}
            />
          </Grid.Col>
        )}
        {!memberProfileSettings.memberPronounsHidden && (
          <Grid.Col md={3} sm={6}>
            <FormikMultiSelect
              name="pronouns"
              label="Pronouns"
              options={pronounsSelectOptions}
            />
          </Grid.Col>
        )}
        {!memberProfileSettings.memberSexualOrientationHidden && (
          <Grid.Col md={3} sm={6}>
            <FormikMultiSelect
              name="sexualOrientation"
              options={sexualOrientationSelectOptions}
              label="Sexual Orientation(s)"
            />
          </Grid.Col>
        )}
        {!memberProfileSettings.memberPregnancyHidden && (
          <Grid.Col md={3} sm={6}>
            <FormikSelect
              name="pregnancyStatus"
              label="Pregnancy Status"
              options={pregnancyStatusSelectOptions}
            />
          </Grid.Col>
        )}
        {!memberProfileSettings.memberLanguagesHidden && (
          <Grid.Col md={3} sm={6}>
            <FormikMultiSelect
              name="spokenLanguages"
              options={languageSelectOptions}
              label="Spoken Language(s)"
            />
          </Grid.Col>
        )}
      </Grid>
    </>
  );
};
