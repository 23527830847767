import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { usePagination } from "src/hooks/usePagination";
import { GET_MEMBERS } from "../Members/queries";
import {
  DefaultResponse,
  GetOnboardingLinkResponse,
  GetOrganizationResponse,
  MutationAddDefaultAnsweringUserArgs,
  MutationCreateOrUpdateOrganizationBillingConfigurationArgs,
  MutationCreateOrganizationArgs,
  MutationUpdateOrganizationArgs,
  QueryOrganizationArgs,
  QueryOrganizationByOnboardingTokenArgs,
  UpdateOrganizationResponse,
  MutationCreateOrUpdateBillingProviderArgs,
  GetBillingProviderResponse,
  MutationDeleteBillingProviderArgs,
  MutationDeleteOrganizationArgs,
  GetOnboardingSurveysResponse,
  QueryOnboardingSurveysByOrganizationIdArgs,
  MutationToggleEpisodesOfCareArgs,
  MutationCreateOrUpdateInNetworkInsuranceBillingConfigurationArgs,
  MutationCreateOrUpdateOrganizationInsuranceBillingConfigurationArgs,
  MutationCreateOrUpdateCostConfigurationsArgs,
  MutationDeleteCostConfigurationArgs,
  GetInsuranceBillingConfigurationsResponse,
  MutationDeleteInsuranceBillingConfigurationArgs,
  GetUrlResponse,
  MutationRenameInsuranceCompanyArgs,
  GetInsuranceAccountTransactionsInput,
  QueryInNetworkInsuranceAccountTransactionsArgs,
  InsuranceAccountTransaction,
  GetInsuranceAccountTransactionsResponse,
  MutationToggleCarePlansArgs,
  MutationCreateDomainArgs,
  DomainResponse,
  MutationUpsertDomainArgs,
  GetDomainsResponse,
  QueryDomainsByOrganizationIdArgs,
  Domain,
} from "../schemaTypes";
import { GET_USER_AND_ORGS } from "../Users/queries";
import {
  ADD_DEFAULT_ANSWERING_USER,
  CREATE_ORGANIZATION,
  CREATE_OR_UPDATE_ORGANIZATION_BILLING_CONFIGURATION,
  DELETE_ONBOARDING_SURVEY,
  GET_ONBOARDING_LINK,
  REMOVE_DEFAULT_ANSWERING_USER,
  UPDATE_ORGANIZATION,
  CREATE_OR_UPDATE_BILLING_PROVIDER,
  DELETE_BILLING_PROVIDER,
  DELETE_ORGANIZATION,
  RESTORE_ORGANIZATION,
  UPDATE_ONBOARDING_SURVEY,
  ENABLE_EPISODES_OF_CARE,
  DELETE_INSURANCE_BILLING_CONFIGURATION,
  CREATE_OR_UPDATE_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION,
  CREATE_OR_UPDATE_COST_CONFIGURATIONS,
  DELETE_COST_CONFIGURATION,
  CREATE_OR_UPDATE_IN_NETWORK_INSURANCE_CONFIG,
  RENAME_INSURANCE_COMPANY,
  TOGGLE_CARE_PLANS,
  CREATE_DOMAIN,
  UPSERT_DOMAIN,
  DELETE_DOMAIN,
} from "./mutations";
import {
  GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS,
  GET_ORGANIZATION,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION_BY_TOKEN,
  GET_ONBOARDING_SURVEYS_BY_ORGANIZATION,
  GET_CUSTOMER_PORTAL_LINK,
  GET_CONNECT_ONBOARDING_LINK,
  GET_DASHBOARD_LINK,
  GET_IN_NETWORK_INSURANCE_ACCOUNT_TRANSACTIONS,
  GET_DOMAINS_BY_ORGANIZATION,
} from "./queries";

export const useQueryOrganization = (organizationId: string) =>
  useQuery<{ organization: GetOrganizationResponse }, QueryOrganizationArgs>(
    GET_ORGANIZATION,
    { fetchPolicy: "network-only", variables: { organizationId } }
  );

export const useQueryOnboardingOrganization = (token: string) =>
  useQuery<
    { organizationByOnboardingToken: GetOrganizationResponse },
    QueryOrganizationByOnboardingTokenArgs
  >(GET_ORGANIZATION_BY_TOKEN, {
    fetchPolicy: "network-only",
    variables: { token },
    context: {
      headers: {
        "Pear-Onboarding-Survey": token,
      },
    },
  });

export const useQueryGetOnboardingSurveys = (organizationId: string) =>
  useQuery<
    { onboardingSurveysByOrganizationId: GetOnboardingSurveysResponse },
    QueryOnboardingSurveysByOrganizationIdArgs
  >(GET_ONBOARDING_SURVEYS_BY_ORGANIZATION, {
    variables: { organizationId },
  });

export const useQueryInNetworkInsuranceBillingConfigurations = (
  skip?: boolean
) =>
  useQuery<{
    inNetworkInsuranceBillingConfigurations: GetInsuranceBillingConfigurationsResponse;
  }>(GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS, { skip });

export const useQueryInNetworkInsuranceAccountTransactions = (
  input: GetInsuranceAccountTransactionsInput
) =>
  usePagination<
    QueryInNetworkInsuranceAccountTransactionsArgs,
    { response: GetInsuranceAccountTransactionsResponse },
    InsuranceAccountTransaction
  >({
    document: GET_IN_NETWORK_INSURANCE_ACCOUNT_TRANSACTIONS,
    variables: { input },
  });

export const useLazyQueryCustomerPortalLink = (organizationId: string) =>
  useLazyQuery<{ customerPortalLink: GetUrlResponse }>(
    GET_CUSTOMER_PORTAL_LINK,
    { variables: { organizationId } }
  );

export const useLazyQueryConnectOnboardingLink = (organizationId: string) =>
  useLazyQuery<{ connectOnboardingLink: GetUrlResponse }>(
    GET_CONNECT_ONBOARDING_LINK,
    { variables: { organizationId } }
  );

export const useLazyQueryAccountDashboardLink = (organizationId: string) =>
  useLazyQuery<{ accountDashboardLink: GetUrlResponse }>(GET_DASHBOARD_LINK, {
    variables: { organizationId },
  });

export const usePaginatedQueryDomains = ({
  organizationId,
  pageSize = 10,
}: {
  organizationId: string;
  pageSize?: number;
}) =>
  usePagination<
    QueryDomainsByOrganizationIdArgs,
    { response: GetDomainsResponse },
    Domain
  >({
    document: GET_DOMAINS_BY_ORGANIZATION,
    variables: { organizationId },
    options: {
      size: pageSize,
    },
  });

export const useMutationUpdateOrganization = (organizationId?: string) =>
  useMutation<
    { updateOrganization: UpdateOrganizationResponse },
    MutationUpdateOrganizationArgs
  >(UPDATE_ORGANIZATION, {
    refetchQueries: [
      { query: GET_ORGANIZATION, variables: { organizationId } },
      GET_ORGANIZATIONS,
    ],
  });

export const useMutationCreateOrganization = (userId: string) =>
  useMutation<
    { createOrganization: DefaultResponse },
    MutationCreateOrganizationArgs
  >(CREATE_ORGANIZATION, {
    refetchQueries: [
      { query: GET_ORGANIZATIONS },
      { query: GET_USER_AND_ORGS, variables: { userId: userId } },
    ],
  });

export const useMutationAddDefaultAnsweringUser = () =>
  useMutation<
    { addDefaultAnsweringUser: DefaultResponse },
    MutationAddDefaultAnsweringUserArgs
  >(ADD_DEFAULT_ANSWERING_USER, {
    refetchQueries: [GET_ORGANIZATION],
  });

export const useMutationRemoveDefaultAnsweringUser = () =>
  useMutation<{ removeDefaultAnsweringUser: DefaultResponse }>(
    REMOVE_DEFAULT_ANSWERING_USER,
    {
      refetchQueries: [GET_ORGANIZATION],
    }
  );

export const useMutationDeleteOnboardingSurvey = () =>
  useMutation<{ deleteOnboardingSurvey: DefaultResponse }>(
    DELETE_ONBOARDING_SURVEY,
    {
      refetchQueries: [
        GET_ORGANIZATION,
        GET_ONBOARDING_SURVEYS_BY_ORGANIZATION,
      ],
    }
  );

export const useMutationOnboardingLink = () =>
  useMutation<{ getOnboardingLink: GetOnboardingLinkResponse }>(
    GET_ONBOARDING_LINK,
    {
      refetchQueries: [
        GET_ORGANIZATION,
        GET_ONBOARDING_SURVEYS_BY_ORGANIZATION,
      ],
    }
  );

export const useMutationUpdateOnboardingSurvey = () =>
  useMutation<{ updateOnboardingSurvey: GetOnboardingLinkResponse }>(
    UPDATE_ONBOARDING_SURVEY,
    {
      refetchQueries: [
        GET_ORGANIZATION,
        GET_ONBOARDING_SURVEYS_BY_ORGANIZATION,
      ],
    }
  );

export const useMutationCreateORUpdateOrganizationBillingConfiguration = () =>
  useMutation<
    {
      createOrUpdateOrganizationBillingConfiguration: GetOrganizationResponse;
    },
    MutationCreateOrUpdateOrganizationBillingConfigurationArgs
  >(CREATE_OR_UPDATE_ORGANIZATION_BILLING_CONFIGURATION, {
    refetchQueries: [GET_ORGANIZATION],
  });

export const useMutationCreateOrUpdateBillingProvider = () =>
  useMutation<
    { createOrUpdateBillingProvider: GetBillingProviderResponse },
    MutationCreateOrUpdateBillingProviderArgs
  >(CREATE_OR_UPDATE_BILLING_PROVIDER, {
    refetchQueries: [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationDeleteBillingProvider = () =>
  useMutation<
    { deleteBillingProvider: DefaultResponse },
    MutationDeleteBillingProviderArgs
  >(DELETE_BILLING_PROVIDER, {
    refetchQueries: [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationToggleEpisodesOfCare = () =>
  useMutation<
    { toggleEpisodesOfCare: GetOrganizationResponse },
    MutationToggleEpisodesOfCareArgs
  >(ENABLE_EPISODES_OF_CARE, {
    refetchQueries: [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationToggleCarePlans = () =>
  useMutation<
    { toggleCarePlans: GetOrganizationResponse },
    MutationToggleCarePlansArgs
  >(TOGGLE_CARE_PLANS, {
    refetchQueries: [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationDeleteOrganization = () =>
  useMutation<
    { deleteOrganization: GetOrganizationResponse },
    MutationDeleteOrganizationArgs
  >(DELETE_ORGANIZATION);

export const useMutationRestoreOrganization = () =>
  useMutation<{ restoreOrganization: GetOrganizationResponse }>(
    RESTORE_ORGANIZATION
  );

export const useMutationCreateOrUpdateOrganizationInsuranceBillingConfiguration =
  () =>
    useMutation<
      {
        createOrUpdateOrganizationInsuranceBillingConfiguration: GetOrganizationResponse;
      },
      MutationCreateOrUpdateOrganizationInsuranceBillingConfigurationArgs
    >(CREATE_OR_UPDATE_ORGANIZATION_INSURANCE_BILLING_CONFIGURATION, {
      refetchQueries: [GET_ORGANIZATION, GET_USER_AND_ORGS],
    });

export const useMutationCreateOrUpdateInNetworkInsuranceBillingConfiguration =
  () =>
    useMutation<
      {
        createOrUpdateInNetworkInsuranceBillingConfiguration: GetInsuranceBillingConfigurationsResponse;
      },
      MutationCreateOrUpdateInNetworkInsuranceBillingConfigurationArgs
    >(CREATE_OR_UPDATE_IN_NETWORK_INSURANCE_CONFIG, {
      refetchQueries: [GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS],
    });

// When these hooks are used in network mode
// They will refetch the in network insurance billing configurations
// which are used to update the connect insurance configurations in organizations

export const useMutationDeleteInsuranceBillingConfiguration = (
  inNetwork = false
) =>
  useMutation<
    { deleteInsuranceBillingConfiguration: DefaultResponse },
    MutationDeleteInsuranceBillingConfigurationArgs
  >(DELETE_INSURANCE_BILLING_CONFIGURATION, {
    refetchQueries: inNetwork
      ? [GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS]
      : [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationCreateOrUpdateCostConfigurations = (
  inNetwork = false
) =>
  useMutation<
    { createOrUpdateCostConfigurations: GetOrganizationResponse },
    MutationCreateOrUpdateCostConfigurationsArgs
  >(CREATE_OR_UPDATE_COST_CONFIGURATIONS, {
    refetchQueries: inNetwork
      ? [GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS]
      : [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationDeleteCostConfiguration = (inNetwork = false) =>
  useMutation<
    { deleteCostConfiguration: GetOrganizationResponse },
    MutationDeleteCostConfigurationArgs
  >(DELETE_COST_CONFIGURATION, {
    refetchQueries: inNetwork
      ? [GET_IN_NETWORK_INSURANCE_BILLING_CONFIGURATIONS]
      : [GET_ORGANIZATION, GET_USER_AND_ORGS],
  });

export const useMutationRenameInsuranceCompany = () =>
  useMutation<
    { renameInsuranceCompany: GetOrganizationResponse },
    MutationRenameInsuranceCompanyArgs
  >(RENAME_INSURANCE_COMPANY, {
    refetchQueries: [GET_MEMBERS, GET_USER_AND_ORGS, GET_ORGANIZATION],
  });

export const useMutationCreateDomain = () =>
  useMutation<{ createDomain: DomainResponse }, MutationCreateDomainArgs>(
    CREATE_DOMAIN,
    {
      refetchQueries: [GET_ORGANIZATION],
    }
  );

export const useMutationUpsertDomain = () =>
  useMutation<{ upsertDomain: DomainResponse }, MutationUpsertDomainArgs>(
    UPSERT_DOMAIN,
    {
      refetchQueries: [GET_ORGANIZATION, GET_DOMAINS_BY_ORGANIZATION],
    }
  );

export const useMutationDeleteDomain = ({ domainId }: { domainId: string }) =>
  useMutation<{ deleteDomain: DefaultResponse }>(DELETE_DOMAIN, {
    refetchQueries: [GET_DOMAINS_BY_ORGANIZATION],
    variables: { domainId },
  });
