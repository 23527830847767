import { useMutation, useQuery } from "@apollo/client";
import {
  GetEventTemplateResponse,
  MutationUpdateEventMemberAttendanceArgs,
  QueryEventTemplateArgs,
  UpdateEventMemberAttendanceResponse,
} from "../schemaTypes";
import { GET_EVENT } from "./queries";
import { UPDATE_EVENT_MEMBER_ATTENDANCE } from "./mutations";
import { GET_ACTIVITY } from "../Activity/queries";

export const useQueryEvent = (id?: string) =>
  useQuery<
    {
      event: GetEventTemplateResponse;
    },
    QueryEventTemplateArgs
  >(GET_EVENT, {
    skip: !id,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: id! },
  });

export const useMutationUpdateEventMemberAttendance = () =>
  useMutation<
    {
      updateMemberEventAttendance: UpdateEventMemberAttendanceResponse;
    },
    MutationUpdateEventMemberAttendanceArgs
  >(UPDATE_EVENT_MEMBER_ATTENDANCE, {
    refetchQueries: [GET_ACTIVITY],
  });
