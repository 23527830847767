import { useRef, useState, useEffect } from "react";
import { Box, Stack, Badge, Text, Checkbox, Group, Flex } from "@mantine/core";
import toast from "src/libs/toast";
import {
  Goal,
  useMutationUpdateGoalProgress,
  GoalStatus,
  useMutationUpdateGoalStatus,
} from "src/graphql";
import { StyledSpoiler, StyledLabel } from "src/components";
import dayjs from "dayjs";
import { useAuthContext } from "src/hooks";

type GoalDetailProps = {
  selectedGoal: Goal;
  /**
   * Show all details of the Goal
   * When `false`, will only show Name, Domains, and ActionItems (currently used in Activity flow)
   */
  showFullDetails?: boolean;
};

export const GoalDetail = ({ selectedGoal }: GoalDetailProps) => {
  const { selectedOrganizationId } = useAuthContext();
  const checkedStatuses = [
    "Complete",
    "Sent",
    "CompletedSuccessful",
    "CompletedUnsuccessful",
  ];

  const uncheckedStatuses = ["InProgress", "Scheduled"];

  const containerRef = useRef<HTMLDivElement>(null);
  const previousGoalIdRef = useRef<string | null>(null);

  const [inFlightIds, setInFlightIds] = useState(new Set<string>());

  const [mutationUpdateGoalProgress] = useMutationUpdateGoalProgress();
  const [mutationUpdateGoalStatus] = useMutationUpdateGoalStatus();

  const handleToggleActionItem = async (itemId: string) => {
    const existing = selectedGoal.actionItems.find(
      (item) => item.id === itemId
    );
    if (!existing) return; // should never be the case

    const nextItems = selectedGoal.actionItems.map((item) =>
      item.id === itemId ? { ...item, completed: !item.completed } : item
    );

    try {
      setInFlightIds((ids) => new Set([...ids, itemId]));
      const res = await mutationUpdateGoalProgress({
        variables: {
          input: {
            goalId: selectedGoal._id,
            memberId: selectedGoal.memberId,
            updateGoal: {
              actionItems: nextItems,
            },
          },
        },
        optimisticResponse: {
          updateGoalProgress: {
            success: true,
            message: "",
            data: {
              ...selectedGoal,
              actionItems: nextItems,
            },
          },
        },
      });

      if (
        res.errors?.length ||
        res.data?.updateGoalProgress?.success === false
      ) {
        throw new Error("Failed to persist ActionItem update");
      }
    } catch {
      toast.error("Couldn't update Action Item, something went wrong!");
      throw new Error("Failed to persist ActionItem update");
    } finally {
      inFlightIds.delete(itemId);
      setInFlightIds(new Set([...inFlightIds]));
    }
  };

  const checkAndUpdateGoalStatus = async (
    nextActionItems = selectedGoal.actionItems
  ) => {
    const allChecked =
      nextActionItems.every((item) => item.completed) &&
      selectedGoal?.activities?.every((activity) =>
        checkedStatuses.includes(activity?.status ?? "")
      ) &&
      selectedGoal?.referrals?.every((referral) =>
        checkedStatuses.includes(referral?.status ?? "")
      ) &&
      selectedGoal?.carePathways?.every((carePathway) =>
        checkedStatuses.includes(carePathway?.status ?? "")
      );

    const dueDate = dayjs(selectedGoal.dueDate);
    const today = dayjs();

    let newStatus = selectedGoal.status;

    if (allChecked) {
      newStatus = "Completed";
    } else if (today.isAfter(dueDate)) {
      newStatus = "Unresolved";
    } else {
      newStatus = "Active";
    }

    if (newStatus !== selectedGoal.status) {
      try {
        await mutationUpdateGoalStatus({
          variables: {
            goalId: selectedGoal._id,
            status: newStatus,
            organizationId: selectedOrganizationId,
          },
        });
      } catch (error) {
        toast.error("Couldn't update goal status, something went wrong!");
      }
    }
  };

  useEffect(() => {
    if (selectedGoal._id !== previousGoalIdRef.current) {
      previousGoalIdRef.current = selectedGoal._id;
      checkAndUpdateGoalStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoal]);

  const renderInterventions = () => {
    const interventions = [
      ...(selectedGoal.activities || []).map((activity) => ({
        type: "Activity",
        id: activity._id,
        title: activity?.title,
        status: activity?.status,
      })),
      ...(selectedGoal.referrals || []).map((referral) => ({
        type: "Referral",
        id: referral._id,
        title: referral?.referralTemplateTitle,
        status: referral?.status,
      })),
      ...(selectedGoal.carePathways || []).map((carePathway) => ({
        type: "Care Pathway",
        id: carePathway._id,
        title:
          carePathway?.name ?? carePathway?.carePathwayTemplate?.name ?? "",
        status: carePathway?.status,
      })),
    ];

    return (
      <Stack spacing={5}>
        <StyledLabel>Interventions:</StyledLabel>
        {interventions.map((intervention) => (
          <Group key={intervention.id}>
            <Checkbox
              checked={
                intervention.status &&
                (checkedStatuses.includes(intervention.status) ||
                  !uncheckedStatuses.includes(intervention.status))
              }
              readOnly
            />
            <Text size="sm">
              {intervention.type}: {intervention.title}
            </Text>
          </Group>
        ))}
      </Stack>
    );
  };

  return (
    <Stack
      w="100%"
      bg="gray.0"
      p={10}
      spacing="xs"
      fz="sm"
      style={{ overflowY: "auto" }}
      pos="relative"
      ref={containerRef}
    >
      {/* Title */}
      <Flex justify="space-between" align="center">
        <Flex direction="column" align="start" gap="sm">
          <Text fw={700}>{selectedGoal.name}</Text>
          {selectedGoal.domain && (
            <Badge color="lightblue" variant="filled" size="xs">
              {selectedGoal.domain.title}
            </Badge>
          )}
        </Flex>

        {/* Date Section */}
        <Box>
          <Text fz="xs">
            <Text component="span" fw={700}>
              Assigned date:
            </Text>{" "}
            {dayjs(selectedGoal.createdAt).format("MMM D, YYYY")}
          </Text>
          {selectedGoal.dueDate && (
            <Text fz="xs">
              <Text component="span" fw={700}>
                Due date:
              </Text>{" "}
              {dayjs(selectedGoal.dueDate).format("MMM D, YYYY")}
            </Text>
          )}
          {/* Status */}
          <Text fz="xs">
            <Text component="span" fw={700}>
              Status:
            </Text>{" "}
            {selectedGoal.status}
          </Text>
        </Box>
      </Flex>
      {/* Description */}
      {selectedGoal.description ? (
        <StyledSpoiler>
          <Text
            dangerouslySetInnerHTML={{ __html: selectedGoal.description }}
          />
        </StyledSpoiler>
      ) : (
        <Text color="gray">&lt;No Description&gt;</Text>
      )}

      {/* Action Items */}
      {!!selectedGoal.actionItems.length && (
        <Stack spacing={5}>
          <StyledLabel>Action Items</StyledLabel>

          {selectedGoal.actionItems.map((item) => (
            <Checkbox
              key={item.id}
              size="xs"
              style={{ flexShrink: 0 }}
              checked={item.completed}
              disabled={
                selectedGoal.status === GoalStatus.Completed ||
                inFlightIds.has(item.id)
              }
              onChange={() => handleToggleActionItem(item.id)}
              label={item.description}
            />
          ))}
          <Box h={44} mt="auto" style={{ flexShrink: 0 }} />
        </Stack>
      )}

      {/* Interventions */}
      {renderInterventions()}
    </Stack>
  );
};
