import { useMutation, useQuery } from "@apollo/client";
import {
  TrainingResponse,
  TrainingsResponse,
  MutationUpsertTrainingArgs,
  QueryTrainingsByUserIdArgs,
  MutationRevokeTrainingArgs,
} from "../schemaTypes";

import { GET_TRAININGS, GET_TRAINING } from "./queries";
import { UPSERT_TRAINING, REVOKE_TRAINING } from "./mutations";

export const useQueryTrainingsByUser = (userId: string) =>
  useQuery<
    {
      trainingsByUserId: TrainingsResponse;
    },
    QueryTrainingsByUserIdArgs
  >(GET_TRAININGS, { variables: { userId } });

export const useMutationUpsertTraining = () =>
  useMutation<{ upsertTraining: TrainingResponse }, MutationUpsertTrainingArgs>(
    UPSERT_TRAINING,
    {
      refetchQueries: [GET_TRAININGS],
    }
  );

export const useMutationRevokeTraining = () =>
  useMutation<{ revokeTraining: TrainingResponse }, MutationRevokeTrainingArgs>(
    REVOKE_TRAINING,
    {
      refetchQueries: [GET_TRAININGS],
    }
  );

export const useQueryTraining = (trainingId: string) =>
  useQuery<
    {
      training: TrainingResponse;
    },
    { trainingId: string }
  >(GET_TRAINING, { variables: { trainingId } });
