import { Checkbox, CheckboxProps } from "@mantine/core";
import { useField } from "formik";
import {
  FormikInputBaseProps,
  FormikInputWrapper,
  extractWrapperProps,
} from "./FormikInputWrapper";

type FormikCheckboxProps = FormikInputBaseProps<boolean> & CheckboxProps;

export const FormikCheckbox = (props: FormikCheckboxProps) => {
  const [field, , helpers] = useField(props);

  return (
    <FormikInputWrapper {...extractWrapperProps(props)} label={undefined}>
      <Checkbox
        {...props}
        checked={Boolean(field.value)}
        onChange={(e) => helpers.setValue(e.currentTarget.checked)}
      />
    </FormikInputWrapper>
  );
};
