import { gql } from "@apollo/client";
import {
  droppedMemberFields,
  memberFields,
  memberSearchInputField,
  memberStatFields,
  unassignedMemberFields,
} from "./types";
import { BillingProviderFields } from "../Organization/types";
import { recommendingProviderFields } from "../RecommendingProvider/types";
import { UserFields } from "../Users/types";
import { PSFileFields } from "../File/types";

export const GET_MEMBER = gql`
  query GetMember($memberId: ID!, $organizationIds: [ID!]!) {
    member(memberId: $memberId, organizationIds: $organizationIds) {
      success
      message
      data {
        ${memberFields}
      }
    }
  }
`;

export const GET_MEMBERS = gql`
  query GetMembers($input: GetMembersInput!, $pagination: PaginationInput!) {
    response: members(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${memberFields}
        }
      }
    }
  }
`;

export const MEMBER_SEARCH = gql`
  query MemberSearch($input: MemberSearchInput!) {
    memberSearch(input: $input) {
      success
      message
      data {
          ${memberSearchInputField}
      }
    }
  }
`;

export const GET_MEMBER_STATS = gql`
  query GetMemberStats($input: GetMemberStatsInput!, $pagination: PaginationInput!) {
    response: memberStats(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${memberStatFields}
        }
      }
    }
  }
`;

export const GET_UNASSIGNED_MEMBERS = gql`
  query GetUnassignedMembers($input: GetUnassignedMembersInput!, $pagination: PaginationInput!) {
    response: unassignedMembers(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${unassignedMemberFields}
        }
      }
    }
  }
`;

export const GET_DROPPED_MEMBERS = gql`
  query GetDroppedMembers($input: GetDroppedMembersInput!, $pagination: PaginationInput!) {
    response: droppedMembers(input: $input, pagination: $pagination) {
      success
      message
      data {
        total
        page
        data {
          ${droppedMemberFields}
        }
      }
    }
  }
`;

export const GET_MEMBER_OVERVIEW_PAGE_DETAILS = gql`
  query GetMemberOverviewPageDetails($memberId: ID!, $organizationId: ID!) {
    memberOverviewPageDetails(
      memberId: $memberId
      organizationId: $organizationId
    ) {
      success
      message
      data {
        title
        type
        metrics {
          label
          dataPointTemplateId
          dataId
          value
          resultLabelValue
          granularData {
            date
            value
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_CARE_PLAN = gql`
  query MemberCarePlan($memberId: ID!, $organizationId: ID!) {
    memberCarePlan(memberId: $memberId, organizationId: $organizationId) {
      success
      message
      data {
        snapshots { 
          _id
          createdAt
          updatedAt
          file {${PSFileFields}}
        }
        domains {
          _id
          title
          description
        }
        billingProvider {${BillingProviderFields}}
        recommendingProvider {${recommendingProviderFields}}
        assignedUsers {${UserFields}}
        memberDataIds {
            label
            value
            dataId
          }
        additionalSections {
          title
          dataIds {
            label
            value
            dataId
          }
        }
      }
    }
  }
`;
