export const GroupFields = `
  _id
  title
  organizationId
  endInDays
  endInWeeks
  endInMonths
  endAt
  createdAt
  updatedAt
`;
