import { gql } from "@apollo/client";
import { EventTemplateFields } from "./types";

export const GET_EVENT_TEMPLATES = gql`
  query GetEventTemplates($organizationId: ID!) {
    eventTemplates(organizationId: $organizationId) {
      success
      message
      data {
        ${EventTemplateFields}
      }
    }
  }
`;

export const GET_EVENT_TEMPLATE = gql`
  query GetEventTemplate($id: ID!) {
    eventTemplate(id: $id) {
      success
      message
      data {
        ${EventTemplateFields}
      }
    }
  }
`;
