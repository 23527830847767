import { useMutation, useQuery } from "@apollo/client";
import { GET_ACTIVITIES } from "../Activity/queries";
import { GET_FLOWS_BY_MEMBER_ID } from "../Flow/queries";
import { GET_GOALS } from "../Goal/queries";
import { GET_MEMBER } from "../Members/queries";
import {
  RecommendationsResponse,
  QueryRecommendationsByOrganizationIdArgs,
  GetRecommendationResponse,
  QueryRecommendationByIdArgs,
  MutationCreateRecommendationArgs,
  MutationUpdateRecommendationArgs,
  MutationDeleteRecommendationArgs,
  QueryMemberRecommendationsArgs,
  MutationPerformRecommendationActionArgs,
  MutationHideRecommendationForMemberArgs,
  CompleteActionResponse,
  DefaultResponse,
} from "../schemaTypes";
import {
  CREATE_RECOMMENDATION,
  DELETE_RECOMMENDATION,
  HIDE_RECOMMENDATION_FOR_MEMBER,
  PERFORM_RECOMMENDATION_ACTION,
  UPDATE_RECOMMENDATION,
} from "./mutations";
import {
  GET_MEMBER_RECOMMENDATIONS,
  GET_RECOMMENDATIONS,
  GET_RECOMMENDATION_BY_ID,
} from "./queries";
import { GET_REFERRALS_BY_MEMBER } from "../Referral/queries";
import { GET_CAREPATHWAY_TEMPLATES } from "../CarePath/queries";
import { CARE_PATHWAYS_BY_MEMBER_ID } from "../CarePathway/queries";

export const useQueryRecommendations = (organizationId: string) =>
  useQuery<
    { recommendationsByOrganizationId: RecommendationsResponse },
    QueryRecommendationsByOrganizationIdArgs
  >(GET_RECOMMENDATIONS, {
    variables: {
      organizationId,
    },
  });

export const useQueryRecommendationById = (
  recommendationId: string,
  skip = false
) =>
  useQuery<
    { recommendationById: GetRecommendationResponse },
    QueryRecommendationByIdArgs
  >(GET_RECOMMENDATION_BY_ID, {
    variables: {
      recommendationId,
    },
    skip,
  });

export const useMutationCreateRecommendation = (organizationId: string) =>
  useMutation<
    { createRecommendation: GetRecommendationResponse },
    MutationCreateRecommendationArgs
  >(CREATE_RECOMMENDATION, {
    update: (cache, result) => {
      const newRecommendation = result.data?.createRecommendation.data;
      if (!newRecommendation) return;

      cache.modify({
        fields: {
          flowTemplates: (cached: RecommendationsResponse) => ({
            ...cached,
            data: [...(cached.data ?? []), newRecommendation],
          }),
        },
      });
    },
    refetchQueries: [
      { query: GET_RECOMMENDATIONS, variables: { organizationId } },
    ],
  });

export const useMutationUpdateRecommendation = (organizationId: string) =>
  useMutation<
    { updateRecommendation: GetRecommendationResponse },
    MutationUpdateRecommendationArgs
  >(UPDATE_RECOMMENDATION, {
    refetchQueries: [
      { query: GET_RECOMMENDATIONS, variables: { organizationId } },
    ],
  });

export const useMutationDeleteRecommendation = (organizationId: string) =>
  useMutation<
    { deleteRecommendation: GetRecommendationResponse },
    MutationDeleteRecommendationArgs
  >(DELETE_RECOMMENDATION, {
    refetchQueries: [
      { query: GET_RECOMMENDATIONS, variables: { organizationId } },
    ],
  });

export const useQueryMemberRecommendations = (
  memberId: string,
  episodeOfCareId?: string | undefined
) =>
  useQuery<
    { memberRecommendations: RecommendationsResponse },
    QueryMemberRecommendationsArgs
  >(GET_MEMBER_RECOMMENDATIONS, {
    variables: {
      memberId,
      episodeOfCareId,
    },
  });

export const useMutationPerformRecommendationAction = (
  memberId: string,
  organizationId: string
) =>
  useMutation<
    { performRecommendationAction: CompleteActionResponse },
    MutationPerformRecommendationActionArgs
  >(PERFORM_RECOMMENDATION_ACTION, {
    refetchQueries: [
      {
        query: GET_GOALS,
        variables: {
          memberId,
        },
      },
      {
        query: GET_MEMBER,
        variables: {
          memberId,
          organizationIds: [organizationId],
        },
      },
      {
        query: GET_FLOWS_BY_MEMBER_ID,
        variables: {
          memberId,
        },
      },
      GET_ACTIVITIES,
      GET_REFERRALS_BY_MEMBER,
      {
        query: GET_CAREPATHWAY_TEMPLATES,
        variables: {
          organizationId,
        },
      },
      {
        query: CARE_PATHWAYS_BY_MEMBER_ID,
        variables: {
          memberId,
        },
      },
    ],
  });

export const useMutationHideRecommendationForMember = () =>
  useMutation<
    { hideRecommendationForMember: DefaultResponse },
    MutationHideRecommendationForMemberArgs
  >(HIDE_RECOMMENDATION_FOR_MEMBER, {
    refetchQueries: [GET_MEMBER_RECOMMENDATIONS],
  });
