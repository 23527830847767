import { gql } from "@apollo/client";
import {
  carePathwayTemplateFields,
  carePathwayTemplateMinimalFields,
  carePathwayFields,
} from "./types";

export const CREATE_OR_UPDATE_CAREPATHWAY_TEMPLATE = gql`
  mutation CreateOrUpdateCarePathwayTemplate($input: CarePathwayTemplateInput!) {
    createOrUpdateCarePathwayTemplate(input: $input) {
      success
      message
      data {
        ${carePathwayTemplateFields}
      }
    }
  }
`;

export const MAKE_CARE_PATHWAY_TEMPLATE_DEFUALT = gql`
  mutation MakeCarePathwayTemplateDefault(
    $carePathwayTemplateId: ID!
    $organizationId: ID!
    ) {
    makeCarePathwayTemplateDefault(
      carePathwayTemplateId: $carePathwayTemplateId
      organizationId: $organizationId
      ) {
      success
      message
      data {
        ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const REMOVE_CARE_PATHWAY_TEMPLATE_FROM_DEFUALT = gql`
  mutation RemoveCarePathwayTemplateFromDefault(
    $carePathwayTemplateId: ID!
    $organizationId: ID!) {
    removeCarePathwayTemplateFromDefault(
      carePathwayTemplateId: $carePathwayTemplateId
      organizationId: $organizationId
      ) {
      success
      message
      data {
        ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const START_CARE_PATHWAY_TEMPLATE_FOR_MEMBER = gql`
  mutation StartCarePathwayTemplateForMember(
    $carePathwayTemplateId: ID!
    $memberId: ID!
    $userId: ID!
    $organizationId: ID!
    $executeActionInput: [ExecuteActionInput]) {
    startCarePathwayTemplateForMember(
    carePathwayTemplateId: $carePathwayTemplateId
    memberId: $memberId
    userId: $userId
    organizationId: $organizationId
    executeActionInput: $executeActionInput) {
      success
      message
      data {
        ${carePathwayFields}
      }
    }
  }
`;

export const DELETE_CARE_PATHWAY_TEMPLATE = gql`
  mutation DeleteCarePathwayTemplate($carePathwayTemplateId: ID!) {
    deleteCarePathwayTemplate(carePathwayTemplateId: $carePathwayTemplateId) {
      success
      message
      data {
        ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const COPY_CARE_PATHWAY_TEMPLATE = gql`
  mutation CopyCarePathwayTemplate($input: CopyCarePathwayTemplateInput!) {
    copyCarePathwayTemplate(input: $input) {
      success
      message
      data {
        ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const RETIRE_CARE_PATHWAY_TEMPLATE = gql`
  mutation CarePathwayTemplate($input: CarePathwayTemplateIdAndOrgInput!) {
    retireCarePathwayTemplate(input: $input) {
      success
      message
      data {
        ${carePathwayTemplateMinimalFields}
      }
    }
  }
`;

export const UPDATE_CARE_PATHWAY_STATUS = gql`
  mutation UpdateCarePathwayStatus($input: UpdateCarePathwayStatusInput!) {
    updateCarePathwayStatus(input: $input) {
      success
        message
        data {
          ${carePathwayFields}
        }
    }
  }
`;
