export const UserFields = `
  _id
  name
  email
  role
  organizationIds
  assignedMemberIds
  createdAt
  updatedAt
  isActive
  spokenLanguages
  location
  trainings {
    _id
    name
    userId
    organizationId
    documentationId
    revokedAt
    issueDate
    expirationDate
    continuingEducationUnits
  }
  recommendingProvider {
    _id
    nationalProviderIdentifier
    firstName
    lastName
    title
    faxNumber
    companyName
    isEmailVerified
  }
`;

export const UserStatFields = `
  _id
  email
  name
  role
  billableUnits
  billableAmount
  numActiveMember
  numActivitiesScheduled
  numActivitiesCompleted
  numGoalsCreated
  numGoalsCompleted
  assignedMembers {
    _id
    email
    name
  }
  isActive
  createdAt
  updatedAt
  status
  onlineDurationInMinutes
`;
