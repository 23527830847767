import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { getAuth } from "firebase/auth";
import { createClient } from "graphql-ws";
import log from "loglevel";
import { v4 as uuid } from "uuid";
import { createInfiniteQueryFieldPolicy } from "src/utils/infinite-query";
import { KEEPALIVE_PING } from "./ClientSession/mutations";

const httpURI = process.env.REACT_APP_GQL_HTTP_URI;
const socketURI = process.env.REACT_APP_GQL_SOCKET_URI;

const httpAuthLink = setContext(async (_, { headers }) => {
  let token = await getAuth().currentUser?.getIdToken();

  // Temporary fix for if token comes back as 1 for some un-debuggable reason.
  if ((token as unknown) === 1) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    token = ((await getAuth().currentUser) as any)?.accessToken ?? "no-token";
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cleanTypeNameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = cleanNestedTypeName(operation.variables);
  }
  return forward(operation);
});

const cleanNestedTypeName = (obj: Record<string | number, unknown>) => {
  let res: Record<string, unknown> = {};

  if (Array.isArray(obj) || (typeof obj === "object" && obj !== null)) {
    for (const key of Object.keys(obj)) {
      if (key === "__typename") {
        continue;
      }

      const value = obj[key];

      // files/blobs
      if (value instanceof File || value instanceof Blob) {
        res[key] = value;
        continue;
      }

      // array case
      if (Array.isArray(value)) {
        res[key] = value.map((v) => cleanNestedTypeName(v));
        continue;
      }

      // object case
      if (typeof value === "object" && value !== null) {
        res[key] = cleanNestedTypeName(value as Record<string, unknown>);
        continue;
      }

      // scalar (base) case
      res[key] = value;
      continue;
    }
  } else {
    res = obj;
  }

  return res;
};

const httpLink = createUploadLink({
  uri: httpURI,
  credentials: "include",
  fetchOptions: {
    mode: "cors",
  },
  headers: {
    "Apollo-Require-Preflight": "true",
  },
});

let clientId: string;

const socketLink = new GraphQLWsLink(
  createClient({
    url: socketURI,
    lazy: true,
    // on socket connections (happens when first subscription is established, post-signin)
    connectionParams: async () => {
      clientId = uuid();
      const token = await getAuth().currentUser?.getIdToken();
      return { token, clientId };
    },
    // sets a keepAlive ping to be sent to the server every 15 seconds as long as connection lives
    keepAlive: 1000 * 15,
    // on:pong means server has received and responded to websocket keepAlive ping
    on: {
      pong: async (...args) => {
        client.mutate({
          mutation: KEEPALIVE_PING,
          variables: { clientId },
        });
      },
    },
  })
);

const retryLink = new RetryLink();

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      log.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) log.error(`[Network error]: ${networkError}`);
});

// link which chooses either socketLink for subscriptions,
// or HTTP for queries/mutations
const splitOperationLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  socketLink,
  httpLink as unknown as ApolloLink
);

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        textMessages: createInfiniteQueryFieldPolicy([
          "input",
          ["organizationId", "participantPhone"],
        ]),
      },
    },

    ActivityTask: {
      keyFields: false,
    },

    ActivityTaskTemplate: {
      keyFields: false,
    },
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([
    retryLink,
    httpAuthLink,
    cleanTypeNameLink,
    errorLink,
    splitOperationLink,
  ]),

  cache: apolloCache,
});

export const cleanupSocketConnection = () => socketLink.client.terminate();

export { client, httpURI as clientURI };
