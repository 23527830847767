import { LockingReferenceFields } from "../Common/types";
import { questionFields } from "../Question/types";
import { actionsFields } from "../Recommendations/types";

export const EventTemplateFields = `
  _id
  organizationId
  title
  description
  questions {
    ${questionFields}
  }
  actionData {
    ${actionsFields}
  }
  lockingReferences {
    ${LockingReferenceFields}
  }
  createdAt
  updatedAt
`;
