import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { usePagination } from "src/hooks/usePagination";
import {
  GetMemberResponse,
  GetMembersInput,
  GetMembersResponse,
  Member,
  MemberSearchResponse,
  MutationCreateMemberArgs,
  MutationCloseMemberArgs,
  MutationUpdateMemberArgs,
  PaginationInput,
  QueryMemberArgs,
  QueryMembersArgs,
  QueryMemberSearchArgs,
  MutationReactivateMemberArgs,
  CreateOrUpdateMemberResponse,
  GetMemberStatsResponse,
  QueryMemberStatsArgs,
  MemberStat,
  MemberStatFilter,
  GetDroppedMembersResponse,
  QueryDroppedMembersArgs,
  DroppedMember,
  MutationBulkCreateMembersArgs,
  BulkCreateMembersResponse,
  GetUnassignedMembersResponse,
  UnassignedMember,
  QueryUnassignedMembersArgs,
  MutationUpdateCoverageDetailsArgs,
  GetMemberOverviewMetricResultsResponse,
  QueryMemberOverviewPageDetailsArgs,
  GetMemberCarePlanResponse,
  QueryMemberCarePlanArgs,
  ProviderRecommendationStatus,
} from "../schemaTypes";
import {
  CREATE_MEMBER,
  CLOSE_MEMBER,
  UPDATE_MEMBER,
  REACTIVATE_MEMBER,
  BULK_CREATE_MEMBERS,
  UPDATE_COVERAGE_DETAILS,
  UPDATE_MEMBER_OVERRIDDEN_PROCEDURE_CODE_LIMITS,
} from "./mutations";
import {
  GET_DROPPED_MEMBERS,
  GET_MEMBER,
  GET_MEMBERS,
  GET_MEMBER_CARE_PLAN,
  GET_MEMBER_OVERVIEW_PAGE_DETAILS,
  GET_MEMBER_STATS,
  GET_UNASSIGNED_MEMBERS,
  MEMBER_SEARCH,
} from "./queries";
import { GET_DATA_BY_MEMBER } from "../DataPoint/queries";

export type BasicMember = Pick<
  Member,
  "_id" | "firstName" | "lastName" | "nickName"
> & { contactInfo: Pick<Member["contactInfo"], "phone"> };

export const useQueryMember = (
  memberId: string,
  organizationIds: string[],
  skip?: boolean
) =>
  useQuery<{ member: GetMemberResponse }, QueryMemberArgs>(GET_MEMBER, {
    variables: { memberId, organizationIds },
    skip,
  });

export const useQueryGetMemberOverviewPageDetails = (
  memberId: string,
  organizationId: string
) =>
  useQuery<
    { memberOverviewPageDetails: GetMemberOverviewMetricResultsResponse },
    QueryMemberOverviewPageDetailsArgs
  >(GET_MEMBER_OVERVIEW_PAGE_DETAILS, {
    variables: { memberId, organizationId },
  });

export const useQueryGetMemberCarePlan = (
  memberId: string,
  organizationId: string
) =>
  useQuery<
    { memberCarePlan: GetMemberCarePlanResponse },
    QueryMemberCarePlanArgs
  >(GET_MEMBER_CARE_PLAN, {
    variables: { memberId, organizationId },
  });

export const usePaginatedQueryMembers = ({
  organizations,
  user,
  pageSize = 20,
  excludeInactive = true,
  showOnlyRecommendationRequired = false,
  unassigned = false,
  groups,
  recommendationStatuses,
}: {
  organizations: string[];
  user?: string;
  pageSize?: number;
  excludeInactive?: boolean;
  showOnlyRecommendationRequired?: boolean;
  unassigned?: boolean;
  groups?: string[];
  recommendationStatuses?: ProviderRecommendationStatus[];
}) =>
  usePagination<QueryMembersArgs, { response: GetMembersResponse }, Member>({
    document: GET_MEMBERS,
    variables: {
      input: {
        organizations,
        user,
        excludeInactive,
        showOnlyRecommendationRequired,
        unassigned,
        groups,
        recommendationStatuses,
      },
    },
    options: {
      size: pageSize,
      defaultSort: {
        field: "firstName",
        direction: 1,
      },
    },
  });

export const usePaginatedQueryUnassignedMembers = ({
  organization,
  pageSize = 20,
}: {
  organization: string;
  pageSize?: number;
}) =>
  usePagination<
    QueryUnassignedMembersArgs,
    { response: GetUnassignedMembersResponse },
    UnassignedMember
  >({
    document: GET_UNASSIGNED_MEMBERS,
    variables: { input: { organization } },
    options: {
      size: pageSize,
      defaultSort: {
        field: "name",
        direction: 1,
      },
    },
  });

export const usePaginatedQueryDroppedMembers = ({
  organization,
  pageSize = 20,
}: {
  organization: string;
  user?: string;
  pageSize?: number;
  excludeInactive?: boolean;
  unassigned?: boolean;
}) =>
  usePagination<
    QueryDroppedMembersArgs,
    { response: GetDroppedMembersResponse },
    DroppedMember
  >({
    document: GET_DROPPED_MEMBERS,
    variables: { input: { organization } },
    options: {
      size: pageSize,
      defaultSort: {
        field: "name",
        direction: 1,
      },
    },
  });

export const usePaginatedQueryMemberStats = (
  organization: string,
  filters?: MemberStatFilter[],
  pageSize = 20,
  excludeInactive = true
) =>
  usePagination<
    QueryMemberStatsArgs,
    { response: GetMemberStatsResponse },
    MemberStat
  >({
    document: GET_MEMBER_STATS,
    variables: { input: { organization, filters, excludeInactive } },
    options: {
      size: pageSize,
    },
  });

export const useRawPaginatedQueryMembers = (
  input: GetMembersInput,
  pagination: PaginationInput
) =>
  useQuery<{ response: GetMembersResponse }, QueryMembersArgs>(GET_MEMBERS, {
    variables: { input, pagination },
  });

export const useMutationBulkCreateMembers = () =>
  useMutation<
    { bulkCreateMembers: BulkCreateMembersResponse },
    MutationBulkCreateMembersArgs
  >(BULK_CREATE_MEMBERS);

export const useMutationCloseMember = (organization: string) =>
  useMutation<{ closeMember: GetMemberResponse }, MutationCloseMemberArgs>(
    CLOSE_MEMBER,
    {
      refetchQueries: [
        {
          query: GET_MEMBERS,
          variables: {
            input: {
              organization,
            },
            pagination: {
              page: 1,
              size: 10,
            },
          },
        },
      ],
    }
  );

export const useMutationReactivateMember = (memberId: string) =>
  useMutation<
    { reactivateMember: GetMemberResponse },
    MutationReactivateMemberArgs
  >(REACTIVATE_MEMBER, {
    variables: { memberId },
    refetchQueries: [
      {
        query: GET_MEMBER,
        variables: {
          memberId,
        },
      },
    ],
  });

export const useMutationCreateMember = (
  organization: string,
  onboardingToken?: string
) =>
  useMutation<
    { createMember: CreateOrUpdateMemberResponse },
    MutationCreateMemberArgs
  >(CREATE_MEMBER, {
    ...(onboardingToken
      ? {
          context: {
            headers: {
              "Pear-Onboarding-Survey": onboardingToken,
            },
          },
        }
      : {
          refetchQueries: [
            {
              query: GET_MEMBERS,
              variables: {
                input: {
                  organization,
                },
                pagination: {
                  page: 1,
                  size: 10,
                },
              },
            },
          ],
        }),
  });

export const useMutationUpdateMember = (
  memberId?: string,
  organizationId?: string
) =>
  useMutation<
    { updateMember: CreateOrUpdateMemberResponse },
    MutationUpdateMemberArgs
  >(UPDATE_MEMBER, {
    refetchQueries: [
      { query: GET_MEMBER, variables: { memberId, organizationId } },
      { query: GET_DATA_BY_MEMBER, variables: { memberId, organizationId } },
      GET_MEMBERS,
    ],
  });

export const useMutationUpdateMemberOverriddenProcedureCodeLimits = () =>
  useMutation<
    { updateMemberOverriddenProcedureCodeLimits: GetMemberResponse },
    { memberId: string; overriddenProcedureCodeLimits: string[] }
  >(UPDATE_MEMBER_OVERRIDDEN_PROCEDURE_CODE_LIMITS);

export const useLazyQueryMemberSearch = () =>
  useLazyQuery<{ memberSearch: MemberSearchResponse }, QueryMemberSearchArgs>(
    MEMBER_SEARCH
  );

export const useMutationUpdateCoverageDetails = () =>
  useMutation<
    { updateCoverageDetails: GetMemberResponse },
    MutationUpdateCoverageDetailsArgs
  >(UPDATE_COVERAGE_DETAILS);

export const useLazyQueryMembers = () =>
  useLazyQuery<{ response: GetMembersResponse }, QueryMembersArgs>(GET_MEMBERS);
